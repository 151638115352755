import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import React from 'react';

const ErrorAlert = ({ alertMessage, alert, setAlert }) => {
  return (
    <Modal
      centered
      // fullscreen="sm"

      size="sm"
      onHide={() => {
        setAlert(false);
      }}
      toggle={() => {
        setAlert(false);
      }}
      isOpen={alert}
      //   className={props.className}
    >
      <div className=" text-dark d-flex align-items-center justify-content-between py-1 px-3">
        <div className="d-flex align-items-center">
          <img src="/img/icons/operate_logo.png" alt="operate-logo" height={50} width={30} />
          <h4 className="mb-0 mx-2">Alert </h4>
        </div>
        <div onClick={() => setAlert(false)}>
          {' '}
          <img src="/img/icons/DENY_BLACK.png" alt="reject" height={25} />
        </div>
      </div>

      <ModalBody>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="msg-wrapper px-3">
              <p>{alertMessage}</p>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="justify-content-center">
        <Button
          outline
          color="danger"
          onClick={() => {
            setAlert(false);
          }}
        >
          Dismiss
        </Button>
        {/* <Button outline color="success" onClick={() => { saveNotfication(notifyMsg) }}>Save</Button>{' '} */}
      </ModalFooter>
    </Modal>
  );
};

export default ErrorAlert;
