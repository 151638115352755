import { RepositoryFactory } from 'repository/RepositoryFactory';
let BestSetterRepository = RepositoryFactory.get('bestseller');
export const getBestSellers = (payload) => async (dispatch) => {
  try {
    let { data } = await BestSetterRepository.getBestSellers(payload);
    // console.log("action best seeleer ddata",data)
    if (data.success) {
      dispatch({
        type: 'GET_BEST_SELLER',
        payload: data.data
      });
    }
  } catch (err) {
    console.log('error', err.message);
  }
};
export const removeBestSeller = (payload, blId) => async (dispatch) => {
  try {
    let res = await BestSetterRepository.removeBestSeller(payload);
    if (res?.data?.success) {
      dispatch(getBestSellers(blId));
      // alert('Deleted Successfully');
      return res?.data?.success;
    }
  } catch (err) {
    console.log('error', err.message);
  }
};

export const addBestSeller = (payload) => async (dispatch) => {
  try {
    let { data } = await BestSetterRepository.addBestSeller(payload);
    if (data.success) {
      dispatch(getBestSellers(payload.blId));
    }
  } catch (err) {
    console.log('error', err.message);
  }
};
