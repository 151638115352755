import { Switch } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { guestWallAction } from 'store/actions/permisionAction';
import { useSelector } from 'react-redux';

const PermissionPage = () => {
  const dispatch = useDispatch();
  const [guestWallPage, setGuestWallPage] = useState(false);
  const { guestWall } = useSelector((state) => state?.permisions);
  const iconLoading = useSelector((state) => state?.permisions?.isload);

  const handleSave = (status) => {
    dispatch(guestWallAction({ isGuestBook: status }));
  };
  useEffect(() => {
    setGuestWallPage(guestWall);
  }, [guestWall]);

  return (
    <>
      <Col xs="12">
        <div className="shadow bg-white rounded mt-5">
          <div className="wrapper p-3 ">
            <div className="title d-flex align-items-center">
              <div className="d-flex align-items-center">
                <img src="/suitcase.png" alt="clock" height="20px" className="mr-3" />
                <span>
                  <h4 className="mb-0 text-black"> Guest Wall Page </h4>
                </span>
              </div>
              <div className="d-flex ml-auto">
                <Button
                  color="primary"
                  className="text-uppercase px-4"
                  name="menu"
                  onClick={() => handleSave(guestWallPage)}
                >
                  Save
                </Button>
              </div>
            </div>
            <Row className="mt-3">
              <Col md="12" className="mt-4 mt-md-0">
                <div className="d-flex align-items-center">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex flex-column flex-shrink-0">
                      <small className="font-weight-semi-bold flex-shrink-0">Enable Page</small>
                    </div>
                    <div className=" d-felx justify-content-between p-2">
                      <span className="text-muted d-flex align-items-center">
                        {iconLoading ? (
                          <span
                            className="spinner-border spinner-border-lg text-info text-center"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <Switch
                            name="guestWall"
                            checked={guestWallPage}
                            onChange={() => {
                              setGuestWallPage(!guestWallPage);
                            }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </>
  );
};
export default PermissionPage;
