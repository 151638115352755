import React, { useEffect } from 'react';
import PageTitle from 'components/PageTitle';
import { Input, Row, Col } from 'reactstrap';
import GuestCard from 'components/GuestBookCard/guestcard';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAllGuests } from 'store/actions/guestActions';
import PermisionPage from './in-house/settings/permissionPage';
import { guestWallStatusAction } from 'store/actions/permisionAction';
const Order = () => {
  const dispatch = useDispatch();
  const { blId } = useSelector((state) => state.blId);
  const { guestWall } = useSelector((state) => state.guest);

  useEffect(() => {
    dispatch(guestWallStatusAction());
    dispatch(getAllGuests(blId));
  }, []);
  console.log('guests', guestWall);
  // const signatures = [
  //   {
  //     sign: '/img/guestcardSignature/1.svg'
  //   },
  //   {
  //     sign: '/img/guestcardSignature/2.png'
  //   },
  //   {
  //     sign: '/img/guestcardSignature/3.png'
  //   },
  //   {
  //     sign: '/img/guestcardSignature/4.jpg'
  //   }
  // ];
  return (
    <>
      <Row className="p-3">
        <Col xs="12" md="6" sm="12" lg="6">
          <div className="card border border-light rounded  head p-2  ">
            <PageTitle icon="/img/sidebar/order.png" title="Guest Book" />
          </div>
        </Col>
        <Col xs="12" md="6" sm="12" lg="6">
          <div className="d-flex align-items-center justify-content-between ">
            <span className="d-flex flex-row ml-auto">
              <Input type="text" placeholder="Search" />
            </span>
          </div>
        </Col>
        <Col xs="12" md="12" sm="12" lg="12" className="p-0">
          <PermisionPage />
        </Col>
      </Row>
      <Row className="p-3">
        {guestWall &&
          guestWall.map((s) => {
            return (
              <GuestCard key={s._id} _id={s._id} blId={blId} signature={s.file} status={s.status} />
            );
          })}
      </Row>

      {/* <div className='mt-3 card'>
				<OrdersTable />
			</div> */}
    </>
  );
};

export default Order;
