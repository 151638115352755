import { UPDATE_SESSION_FAIL ,UPDATE_SESSION_REQUEST,UPDATE_SESSION_SUCCESS} from "store/Types/SessionType";
let initState = {
  getFloorPlansLoader: false,
  floorPlans: [],
  getSessionsLoader: false,
  sessions: [],
  allOrders: [],
  removeSessionLoader: false,
  paywaiterLoader: false,
  updateSessionLoader:false,
};
export default function (state = initState, action) {
  switch (action.type) {
    case 'GET_FLOOR_PLANS':
      return {
        ...state,
        floorPlans: action.payload
      };
    case 'GET_FLOOR_PLANS_LOADER':
      return {
        ...state,
        getFloorPlansLoader: action.payload
      };
    case 'GET_SESSIONS':
      return {
        ...state,
        sessions: action.payload
      };
    case 'GET_SESSIONS_LOADER':
      return {
        ...state,
        getSessionsLoader: action.payload
      };
    case 'REMOVE_SESSION_LOADER':
      return {
        ...state,
        removeSessionLoader: action.payload
      };
    case 'PAY_WAITER_LOADER':
      return {
        ...state,
        paywaiterLoader: action.payload
      };
    case 'GET_ORDERS': {
      return {
        ...state,
        allOrders: action.payload
      };
    }
    case 'REMOVE_ORDER': {
      let orders = [...state.allOrders];
      let orderIndex = orders.findIndex((item) => item.sessionId == action.payload);
      orders.splice(orderIndex, 1);
      let sessions = [...state.sessions];
      let sessionIndex = sessions.findIndex((item) => item._id == action.payload);
      sessions.splice(sessionIndex, 1);
      return {
        ...state,
        allOrders: orders,
        sessions:sessions
      };
    }

    case UPDATE_SESSION_REQUEST:
      return {
        ...state,
        updateSessionLoader: true
      };
    case UPDATE_SESSION_SUCCESS:
      var allSEssions = state.sessions;
      var selectedSessionIndex = allSEssions.findIndex((_item) => _item._id === action.payload._id);
      allSEssions[selectedSessionIndex] = action.payload;
      return {
        ...state,
        sessions: allSEssions,
        updateSessionLoader: false
      };
      case UPDATE_SESSION_FAIL:
        return {
          ...state,
          updateSessionLoader: false
        };
      
    default:
      return { ...state };
  }
}
