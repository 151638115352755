import axios from 'axios';
// const baseDomain = 'http://localhost:5000';
// const baseDomain = 'https://vapiano-test.herokuapp.com';
const baseDomain = process.env.REACT_APP_BackendURL;
const baseURL = `${baseDomain}`;

let axiosObj;
axiosObj = axios.create({
  baseURL
});
axiosObj.interceptors.request.use(
  function (config) {
    const blId = sessionStorage.getItem('blId');
    if (blId) {
      config.headers['blId'] = blId;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosObj;
