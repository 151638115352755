import React, { useState } from 'react';
import './ToggleSwitch.css';

function ToggleSwitch(props) {
  const [isToggled, setIsToggled] = useState(false);
  // const onToggle = () => setIsToggled(!isToggled);
  const onChangeHandler = () => {
    setIsToggled(!isToggled);
    props.onChangeHandler(isToggled);
  };
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={props.Check} onChange={onChangeHandler} />
      <span className="switch" />
    </label>
  );
}
export default ToggleSwitch;
