import { socket } from 'services/services.sockets';
import { UPDATE_SESSION_REQUEST,UPDATE_SESSION_SUCCESS,UPDATE_SESSION_FAIL } from 'store/Types/SessionType';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
var floorPlansRepository = RepositoryFactory.get('floor_plans');
let sessionRepo = RepositoryFactory.get('session');
export const getFloorPlansLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'GET_FLOOR_PLANS_LOADER',
    payload: val
  });
};

export const getSessionsLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'GET_SESSIONS_LOADER',
    payload: val
  });
};

export const removeSessionLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'REMOVE_SESSION_LOADER',
    payload: val
  });
};
export const payWaiterLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'PAY_WAITER_LOADER',
    payload: val
  });
};

export const getAllOrders = (payload) => async (dispatch) => {
  try {
    dispatch(getFloorPlansLoader(true));
    let res = await floorPlansRepository.getOrders(payload);
    if (res?.data?.success) {
      dispatch({
        type: 'GET_ORDERS',
        payload: res.data.data
      });
    }
    dispatch(getFloorPlansLoader(false));
  } catch (e) {
    console.log(e);
    dispatch({
      type: 'GET_FLOOR_PLANS',
      payload: []
    });
    dispatch(getFloorPlansLoader(false));
  }
};

export const getAllFloorPlans = (id) => async (dispatch) => {
  dispatch(getFloorPlansLoader(true));
  let { data } = await floorPlansRepository.getAllFloorplans(id);
  try {
    if (data.success) {
      dispatch({
        type: 'GET_FLOOR_PLANS',
        payload: data.data
      });
      dispatch(getFloorPlansLoader(false));
    } else {
      dispatch(getFloorPlansLoader(false));
    }
  } catch (e) {
    dispatch({
      type: 'GET_FLOOR_PLANS',
      payload: []
    });
    dispatch(getFloorPlansLoader(false));
  }
};

export const getAllSessions = (id) => async (dispatch) => {
  dispatch(getSessionsLoader(true));
  let { data } = await floorPlansRepository.getAllSessions(id);
  try {
    if (data.success) {
      dispatch({
        type: 'GET_SESSIONS',
        payload: data.data
      });
      dispatch(getSessionsLoader(false));
    } else {
      dispatch(getSessionsLoader(false));
    }
  } catch (e) {
    dispatch({
      type: 'GET_SESSIONS',
      payload: []
    });
    dispatch(getSessionsLoader(false));
  }
};

export const removeSession = (id) => async (dispatch) => {
  dispatch(removeSessionLoader(true));
  try {
    let { data } = await floorPlansRepository.removeSession(id);

    if (data.success) {
      alert(data.message);
      dispatch({
        type: 'REMOVE_ORDER',
        payload: id
      });
      dispatch(removeSessionLoader(false));
      socket.emit('tableCreated', { deleted: true, id: id });
    } else {
      dispatch(removeSessionLoader(false));
    }
  } catch (e) {
    alert(e.response?.data?.message);
    dispatch(removeSessionLoader(false));
  }
};
export const payWaiter = (payload) => async (dispatch) => {
  if (!payload.tableNumber || !payload.businessLocationId) {
    alert('Make sure you have: \n 1- Add items to cart \n 2- Joined a table');
    return window.location.replace('/home');
  }

  const checkOrder = (statusInfo) => {
    dispatch(payWaiterLoader(false));
    if (statusInfo?.status === 200) {
      alert('Paid Success Fully');
      dispatch(getAllOrders());
      return statusInfo;
    } else {
      alert('order failed');
    }
  };

  try {
    dispatch(payWaiterLoader(true));
    floorPlansRepository.payWaiterOrder(payload).then((statInfo) => checkOrder(statInfo));
  } catch (err) {
    dispatch(payWaiterLoader(false));
    alert('Order Failed:' + ' ' + err?.response?.data?.message);
  }
};

export const updateSession =(val)=>async(dispatch) =>{
  try{
    dispatch({ type: UPDATE_SESSION_REQUEST });
    let res = await sessionRepo.updateSessionRepo(val);
    if(res){
      dispatch({
        type: UPDATE_SESSION_SUCCESS,
        payload: res?.data?.data
      });
    }
    return res?.data
  }
  catch(error){
    console.log(error)
    dispatch({
      type: UPDATE_SESSION_FAIL,
      payload: error
    });
    return error?.response?.data?.message
    // alert(error?.response?.data?.message)
  }
  }
