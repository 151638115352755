const initialData = {
  blId: localStorage.getItem('blId')
};

const blIdReducers = (state = initialData, action) => {
  switch (action.type) {
    case 'ADD_BUSINESS_ID':
      return {
        ...state,
        blId: action.payload
      };
    default:
      return state;
  }
};

export default blIdReducers;
