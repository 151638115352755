import { RepositoryFactory } from '../../repository/RepositoryFactory';
let UsersRepository = RepositoryFactory.get('user_detail');
// var StationsRepository = RepositoryFactory.get('stations');
export const getUsersLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'GET_USERS_LOADER',
    payload: val
  });
};

export const addUserLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'ADD_USER_LOADER',
    payload: val
  });
};

export const updateUserLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_USER_LOADER',
    payload: val
  });
};

export const getAllUsers = (id) => async (dispatch) => {
  dispatch(getUsersLoader(true));
  try {
    let { data } = await UsersRepository.getAllUsers(id);
    if (data.success) {
      dispatch(getUsersLoader(true));
      // console.log("data success", data)
      dispatch({
        type: 'GET_ALL_USERS',
        payload: data.data
      });
      dispatch(getUsersLoader(false));
    } else {
      dispatch(getUsersLoader(false));
    }
  } catch (err) {
    alert(err.message);
    dispatch(getUsersLoader(false));
  }
};
export const createUser =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(addUserLoader(true));
    try {
      let res = await UsersRepository.createUsers(payload);
      if (res?.data?.success) {
        dispatch(getAllUsers(payload.blId));
        // alert('USER Added succesfully');
        onSuccess();
        dispatch(addUserLoader(false));
        return res?.data?.success;
      } else {
        dispatch(addUserLoader(false));
      }
    } catch (err) {
      alert(err.message);
      dispatch(addUserLoader(false));
    }
  };
export const updateUser =
  (payload, _id, blId, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(updateUserLoader(true));
    try {
      let res = await UsersRepository.updateUser(payload, _id);
      if (res?.data?.success) {
        dispatch(getAllUsers(blId));
        // alert('USER Updated succesfully');
        onSuccess();
        dispatch(updateUserLoader(false));
        return res?.data?.success;
      } else {
        dispatch(updateUserLoader(false));
      }
    } catch (err) {
      console.log('error', err);
      alert('This Pin Code already in use pleasae select another one');
      dispatch(updateUserLoader(false));
    }
  };
export const deleteUser =
  ({ id, blId }) =>
  async (dispatch) => {
    try {
      await UsersRepository.deleteUser(id);
      alert('User Deleted');
      dispatch(getAllUsers(blId));
    } catch (err) {
      console.log('error', err.message);
    }
  };

// try {
//   let { data } = await UsersRepository.deleteUser(_id);
//   //   console.log("item deleted");
//   alert("User Deleted");
//   dispatch(getAllUsers(payload.blId));
// } catch (err) {
//   console.log("error", err.message);
// }
