import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Input, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap';
import { updateSession } from 'store/actions/floorPlanActions';

const CreateSessionModal = ({
  openAddSessionModal,
  setOpenAddSessionModal,
  selectedOrder,
  setAlertMsg,
  setWarn
}) => {
  const dispatch = useDispatch();
  const [session, setSession] = useState('');
  const [sessionsArr, setArrSessions] = useState([]);
  const [removeSelect, setRemoveSelected] = useState('');
  const updateSessionCardLoader = useSelector((state) => state?.floor_plans?.updateSessionLoader);

  const sessionAdd = () => {
    setArrSessions([...sessionsArr, session]);
    setSession('');
  };
  const removeID = (_session) => {
    if (!removeSelect) {
      setRemoveSelected(_session);
    } else {
      let array = [...sessionsArr];
      array.splice(removeSelect, 1);
      setArrSessions([...array]);
      setRemoveSelected('');
    }
  };
  const updateSessionFunc = () => {
    if (sessionsArr.length > 0) {
      dispatch(
        updateSession({
          businessLocationId: selectedOrder?.businessLocationId,
          table_id: selectedOrder?.sessionId,
          cardData: sessionsArr
        })
      ).then((res) => {
        clearData();
        setOpenAddSessionModal(false);
        if (res.success) {
          setWarn(true);
          setAlertMsg('Session updated');
          return;
        }
        setWarn(true);
        setAlertMsg(res);
      });
    } else {
      setWarn(true);
      setAlertMsg("There is no id input");
    }
  };
  const clearData = () => {
    setRemoveSelected('');
    setArrSessions([]);
    setSession('');
  };

  return (
    <Modal
      centered
      // fullscreen="sm"

      size="md"
      onHide={() => {
        setOpenAddSessionModal(false);
      }}
      toggle={() => {
        setOpenAddSessionModal(false);
      }}
      isOpen={openAddSessionModal}
      //   className={props.className}
    >
      <div className=" text-dark d-flex align-items-center justify-content-between py-1 px-3">
        <div className="d-flex align-items-center">
          <img src="/img/icons/operate_logo.png" alt="operate-logo" height={50} width={30} />
          <h4 className="mb-0 mx-2">Create Session</h4>
        </div>
        <div onClick={() => setOpenAddSessionModal(false)}>
          {' '}
          <img src="/img/icons/DENY_BLACK.png" alt="reject" height={25} />
        </div>
      </div>

      <ModalBody>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="card p-2 border border-light rounded mt-3">
              <Row className="p-3">
                <Col xs="8" sm="8" md="8" lg="8">
                  <Input
                    name="session"
                    value={session}
                    onChange={(e) => setSession(e.target.value)}
                    placeholder="Please input an Id"
                  />
                </Col>
                <Col xs="4" sm="4" md="4" lg="4">
                  <Button
                    onClick={() => {
                      sessionAdd();
                    }}>
                    ADD ID
                  </Button>
                </Col>
                {sessionsArr.length > 0 ? (
                  <Col xs="12" sm="12" md="12" lg="12 " className="mt-2">
                    <div className="border rounded p-2">
                      {sessionsArr.map((_session, index) => (
                        <Badge
                          className="m-2 p-3"
                          color={removeSelect === index ? 'danger' : 'info'}
                          pill
                          key={index}
                          onClick={() => {
                            removeID(index);
                          }}>
                          {removeSelect === index ? 'Delete' : _session}
                        </Badge>
                      ))}
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              <div className="d-flex justify-content-center py-3">
                <Button
                  color="info"
                  disabled={updateSessionCardLoader}
                  onClick={() => {
                    updateSessionFunc();
                  }}>
                  {!updateSessionCardLoader ? 'Update Session' : <Spinner />}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="justify-content-center">
        <Button
          outline
          color="danger"
          onClick={() => {
            setOpenAddSessionModal(false);
          }}>
          Dismiss
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateSessionModal;
