import IngredientItem from 'components/IngredientItem';
import PageTitle from 'components/PageTitle';
import React, { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap';
import { getAllIngredientsAct } from 'store/actions/ingredientActions';

const Ingredients = () => {
  const dispatch = useDispatch();
  const { getIngrLoader, ingredients, ingredientsAll } = useSelector((state) => state.ingredient);
  let [activeArticles, setActiveArticles] = useState([]);
  let [addArticleModal, setAddArticleModal] = useState(false);
  const { blId } = useSelector((state) => state.blId);
  // let [ingerdients, setIngerdients] = useState(Ingerdients);

  // let articles = new Array(4).fill(1).map((_, key) => ({ id: key }));
  const isIngredientActive = (idx) => {
    return activeArticles.indexOf(idx) != -1;
  };
  const toggleIngredientActive = (idx) => {
    setActiveArticles((prevProp) => {
      let articleIdx = activeArticles.indexOf(idx);

      if (articleIdx == -1) {
        prevProp.push(idx);
        return [...prevProp];
      } else {
        prevProp.splice(articleIdx, 1);
        return [...prevProp];
      }
    });
  };
  const toggleAddArticleModal = () => {
    setAddArticleModal(!addArticleModal);
  };
  const expandAll = () => {
    setActiveArticles(() => {
      return ingredients.map((article) => article.id);
    });
  };
  const collapseAll = () => {
    setActiveArticles([]);
  };
  //Get Business Location Id of Current Store
  // let ls = localStorage.getItem('blId');

  useEffect(() => {
    if (ingredientsAll && ingredientsAll.length == 0) {
      dispatch(getAllIngredientsAct(blId));
    }
  }, [blId]);

  return (
    <>
      <div className="pb-4">
        <div className="d-block d-md-flex align-items-center">
          <div className="d-flex align-items-center">
            <PageTitle title="Ingredients" icon="/img/sidebar/bowl.png" />
            <span className="ml-3">
              <i className="fas fa-wrench"></i>
            </span>
            <FormGroup className="ml-3" check>
              <Label check>
                <Input className="mt-2" type="checkbox" />{' '}
                <small className="font-weight-bold">POS inactive</small>
              </Label>
            </FormGroup>
          </div>
          <div className="d-block d-sm-flex ml-auto mt-3 mt-md-0 align-items-center">
            <Button color="light" size="sm" onClick={expandAll}>
              Expand All <i className="fa fa-angle-down"></i>
            </Button>
            <Button color="light" size="sm" onClick={collapseAll}>
              Collapse All <i className="fa fa-angle-up"></i>
            </Button>
            <br />
            <Button color="primary" className="mt-3 mt-sm-0" onClick={toggleAddArticleModal}>
              Add Group
              <i className="ml-2 fa fa-plus-circle"></i>
            </Button>
          </div>
        </div>
        <div className="border mt-3 card">
          <DragDropContext>
            <Droppable droppableId="droppableArticle">
              {(provided) => (
                <div ref={provided.innerRef} className="d-flex flex-column">
                  {getIngrLoader ? (
                    <Spinner className="d-flex mx-auto mt-5" size="lg" color="primary" />
                  ) : (
                    <>
                      {ingredientsAll &&
                        ingredientsAll.map((ingredient, k) => (
                          <Draggable key={k} draggableId={k.toString()} index={k}>
                            {(provided) => (
                              <IngredientItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ingredient={ingredient}
                                isActive={isIngredientActive(ingredient.id)}
                                toggleActive={() => toggleIngredientActive(ingredient.id)}
                              />
                            )}
                          </Draggable>
                        ))}
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <Modal centered isOpen={addArticleModal} toggle={toggleAddArticleModal}>
        <ModalHeader toggle={toggleAddArticleModal}>Add Ingredients</ModalHeader>
        <Form>
          <ModalBody>
            <FormGroup>
              <Label>Ingredients Name</Label>
              <Input type="text" />
            </FormGroup>
            <FormGroup>
              <Label>Ingredients Price</Label>
              <Input type="text" />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Save</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Ingredients;
