import PageTitle from 'components/PageTitle';
import QrCode from 'components/QrCode/Qrocde';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { getAllFloorPlans } from 'store/actions/floorPlanActions';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../components/Pagination';

let tableNo = null;
let code = null;
const QrCodes = () => {
  var location = useLocation();
  const dispatch = useDispatch();

  // const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    {
      tableno: 41,
      code: 420050,
      qrcode: '/img/QrCode/qr1.png'
    },
    {
      tableno: 42,
      code: 420051,
      qrcode: '/img/QrCode/qr2.png'
    },
    {
      tableno: 43,
      code: 420052,
      qrcode: '/img/QrCode/qr3.png'
    },
    {
      tableno: 44,
      code: 420053,
      qrcode: '/img/QrCode/qr4.png'
    },
    {
      tableno: 45,
      code: 420054,
      qrcode: '/img/QrCode/qr5.png'
    },
    {
      tableno: 46,
      code: 420055,
      qrcode: '/img/QrCode/qr6.png'
    },
    {
      tableno: 47,
      code: 420056,
      qrcode: '/img/QrCode/qr7.png'
    },
    {
      tableno: 48,
      code: 420057,
      qrcode: '/img/QrCode/qr8.png'
    },
    {
      tableno: 49,
      code: 420058,
      qrcode: '/img/QrCode/qr9.png'
    },
    {
      tableno: 50,
      code: 420059,
      qrcode: '/img/QrCode/qr10.png'
    },
    {
      tableno: '51',
      code: '420052',
      qrcode: '/img/QrCode/qr12.png'
    },
    {
      tableno: '52',
      code: '420053',
      qrcode: '/img/QrCode/qr13.png'
    },
    {
      tableno: '53',
      code: '420054',
      qrcode: '/img/QrCode/qr14.png'
    }
  ]);
  // const [page, setPage] = useState(1);
  if (tableNo == null && code == null) {
    let lastItem = data[data.length - 1];

    // console.log('last=>', lastItem);
    let { tableno: lastTableNo, code: lastItemCode } = lastItem;

    tableNo = Number(lastTableNo) + 1;
    code = Number(lastItemCode) + 1;
  }

  const { floorPlans, getFloorPlansLoader } = useSelector((state) => state.floor_plans);
  const { blId } = useSelector((state) => state.blId);
  const [postsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = floorPlans
    // .filter((data) => data.name.toLowerCase() != "takeaway")
    .slice(indexOfFirstPost, indexOfLastPost);
  const currentTakeAwayPosts = floorPlans
    .filter((data) => data.name.toLowerCase() === 'takeaway')
    .slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const addQr = () => {
  //   // setLoading(true);
  //   setData((prevState) => {
  //     return [
  //       ...prevState,
  //       {
  //         tableno: tableNo,
  //         code: code,
  //         qrcode: '/img/QrCode/qr10.png'
  //       }
  //     ];
  //   });
  //   tableNo += 1;
  //   code += 1;
  //   // setLoading(false);
  // };

  // const getData = () => {
  //   let tempData = JSON.parse(JSON.stringify(data));
  //   return tempData.slice((page - 1) * 12, page * 12);
  // };

  useEffect(() => {
    // if (floorPlans && floorPlans.length == 0) {
    dispatch(getAllFloorPlans(blId));
    // }
  }, [blId]);

  return (
    <>
      <Row className="p-3">
        <div className="card p-2 border border-light rounded w-50">
          <PageTitle icon={'/img/sidebar/qr-code.png'} title="Qr Code" />
        </div>
        <Col xs="12" sm="12" md="12" lg="12" className="d-flex mt-3">
          {/* <p>In house QR Codes-VAPIANO DUESSELDORF 35</p> */}
          {/* {loading ? (
						<Spinner />
					) : location.pathname == '/store/in-store/qr-codes' ? (
						<Button className='ml-auto mb-4' onClick={addQr}>
							Add QrCode
						</Button>
					) : (
						''
					)} */}
        </Col>

        {location.pathname === '/store/in-store/qr-codes' ? (
          <QrCode setData={setData} data={currentPosts} />
        ) : location.pathname === '/store/takeaway-delivery/qr-codes' ? (
          <QrCode setData={setData} data={currentTakeAwayPosts} takeAway={true} />
        ) : null}

        {location.pathname == '/store/in-store/qr-codes' ? (
          <Col xs="12" sm="12" md="12" lg="12">
            {getFloorPlansLoader ? (
              <div></div>
            ) : (
              <div className="mt-3">
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={
                    // .filter(
                    //   (data) => data.name.toLowerCase() != "takeaway"
                    // )
                    floorPlans.length
                  }
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            )}

            {/* <div className="d-flex">
              <Pagination className="ml-auto">
                {1 != page && (
                  <PaginationItem onClick={(e) => setPage(1)}>
                    <PaginationLink previous href="#" />
                  </PaginationItem>
                )}
                {new Array(Math.ceil(data.length / 12)).fill(1).map((_, k) => (
                  <PaginationItem
                    active={page == k + 1}
                    onClick={(e) => setPage(k + 1)}
                  >
                    <PaginationLink href="#">{k + 1}</PaginationLink>
                  </PaginationItem>
                ))}
                {Math.ceil(data.length / 12) != page && (
                  <PaginationItem
                    onClick={(e) => setPage(Math.ceil(data.length / 12))}
                  >
                    <PaginationLink next href="#" />
                  </PaginationItem>
                )}
              </Pagination>
            </div> */}
          </Col>
        ) : (
          <Col xs="12" sm="12" md="12" lg="12">
            {getFloorPlansLoader ? (
              <div></div>
            ) : (
              <div className="mt-3">
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={
                    floorPlans.filter((data) => data.name.toLowerCase() == 'takeaway').length
                  }
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            )}

            {/* <div className="d-flex">
              <Pagination className="ml-auto">
                {1 != page && (
                  <PaginationItem onClick={(e) => setPage(1)}>
                    <PaginationLink previous href="#" />
                  </PaginationItem>
                )}
                {new Array(Math.ceil(data.length / 12)).fill(1).map((_, k) => (
                  <PaginationItem
                    active={page == k + 1}
                    onClick={(e) => setPage(k + 1)}
                  >
                    <PaginationLink href="#">{k + 1}</PaginationLink>
                  </PaginationItem>
                ))}
                {Math.ceil(data.length / 12) != page && (
                  <PaginationItem
                    onClick={(e) => setPage(Math.ceil(data.length / 12))}
                  >
                    <PaginationLink next href="#" />
                  </PaginationItem>
                )}
              </Pagination>
            </div> */}
          </Col>
        )}
      </Row>
    </>
  );
};

export default QrCodes;
