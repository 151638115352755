/*!

=========================================================
* Vapiano Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Elktech (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Elktech

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
import Operate from 'assets/img/operate.png';

const AdminNavbar = () => {
  return (
    <>
      <div id="navbar-main">
        <Container fluid className="d-flex align-items-center">
          <span
            className="text-white cursor-pointer"
            onClick={() => {
              document.body.classList.remove('submenu-opened');
              if (document.body.classList.contains('sidebar-collapsed'))
                document.body.classList.remove('sidebar-collapsed');
              else document.body.classList.add('sidebar-collapsed');
            }}
          >
            <i className="fa fa-bars"></i>
          </span>
          <Link
            className="h4 m-0 ml-4 mb-0 text-white text-uppercase d-flex align-items-center"
            to="/"
          >
            <img src={Operate} />
          </Link>

          <small
            className={`font-weight-bold  text-white ml-5 pl-5 ${
              window.location.href.includes('admin') ? 'd-none' : 'd-inline-block'
            }`}
          >
            {localStorage.getItem('blName')}
          </small>
          <Link className="ml-auto" to="/auth/login">
            <i className="text-white fa fa-cog"></i>
          </Link>
        </Container>
      </div>
    </>
  );
};

export default AdminNavbar;
