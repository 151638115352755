import Repository from './Repository';
const appSettings = '/business';

export default {
  generalSettings(payload) {
    return Repository.put(`${appSettings}/update`, payload);
  },
  updateAppColor(payload, id) {
    return Repository.put(`${appSettings}/${id}`, payload);
  },
  updateRightIcon(payload) {
    let form = new FormData();
    form.append('blId', payload.blId);
    form.append('type', payload.type);
    form.append('sub_page', payload.sub_page);
    form.append('file', payload.file);

    // Repository.headers ={ 'Content-Type': `multipart/form-data` }
    // Repository.data = payload

    return Repository.put(`${appSettings}`, form);
  },
  getBusinessDetails(payload) {
    return Repository.post(`${appSettings}`, payload);
  },
  repoupdateText(payload){
    return Repository.put(`${appSettings}/update-text/${payload?.blId}`,{
      heading_text:  payload?.heading_text,
      primary_text: payload?.primary_text,
      secondary_text: payload?.secondary_text,
      modal_type: payload?.modal_type
    }
    )
  },
  getAppText(payload){
    return Repository.get(`${appSettings}/getLayoutText/${payload}`)
  }

};
