/* eslint-disable no-undef */
import React from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col } from 'reactstrap';
import { UpdateText } from 'store/actions/settingsAction';
import AddText from './addtextComp';
const TextUpdate = ({ titlePage,heading_text, primary_text, secondary_text,data ,modal_type}) => {
 
 
  const dispatch = useDispatch();
  const {blId} = useSelector ((state)=>state?.business)

  const headingTitle = useRef(null);
  const primary = useRef(null);
  const secondary = useRef(null);

  const saveText = () => {
    if (headingTitle.current.value || primary.current.value || secondary.current.value) {
      dispatch(
        UpdateText({
          heading_text: headingTitle.current.value && headingTitle.current.value,
          primary_text: primary.current.value && primary.current.value,
          secondary_text: secondary.current.value && secondary.current.value,
          modal_type:modal_type,
          blId
        })
      );
      ClearValues();
    }
  };
  const ClearValues = () => {
    headingTitle.current.value = '';
    primary.current.value = '';
    secondary.current.value = '';
  };
  console.log(data?.modal_type, "the data");
  return (
    <Col md="12" lg="12" sm="12" xs="12" className="mt-2">
      <div className="shadow bg-white rounded p-3">
        <div className="d-flex justify-content-between">
          <h4>{titlePage}</h4>
          <Button
            color="primary"
            className="text-uppercase px-4"
            onClick={() => {
              saveText();
            }}>
            Save
          </Button>
        </div>
        {/* order Modal Setting */}
        <AddText name={headingTitle} menuTitle={heading_text} value ={data?.heading_text || "Please Enter Heading"}/>
        {primary_text && <AddText name={primary} menuTitle={primary_text} value ={data?.primary_text  || "Please Enter First Heading"} />}
        {secondary_text && <AddText name={secondary} menuTitle={secondary_text} value ={data?.secondary_text || "Please Enter Second Heading"}/>}
      </div>
    </Col>
  );
};

export default TextUpdate;
