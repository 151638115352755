const initialData = {
  popup: {},
  header: [],
  news: [],
  loader_popup: false,
  loader_header: false,
  loader_news: false
};
const promotionReducers = (state = initialData, action) => {
  switch (action.type) {
    case 'GET_POP_UP':
      return {
        ...state,
        popup: action.payload
      };
    case 'ADD_POP_UP':
      return {
        ...state,
        popup: action.payload
      };
    case 'DELETE_POP_UP':
      return {
        ...state,
        popup: action.payload
      };
    case 'GET_HEADER':
      return {
        ...state,
        header: action.payload
      };
    case 'ADD_HEADER':
      return {
        ...state,
        header: [...state.header, action.payload]
      };
    case 'DELETE_HEADER':
      return {
        ...state,
        header: state.list.filter((elem) => elem.id !== action.id)
      };
    case 'GET_NEWS':
      return {
        ...state,
        news: action.payload
      };
    case 'ADD_NEWS':
      return {
        ...state,
        news: [...state.news, action.payload]
      };
    case 'DELETE_NEWS':
      return {
        news: state.list.filter((elem) => elem.id !== action.id)
      };
    case 'SET_POPUP_LOADING':
      return {
        ...state,
        loader_popup: action.payload
      };
    case 'SET_HEADER_LOADING':
      return {
        ...state,
        loader_header: action.payload
      };
    case 'SET_NEWS_LOADING':
      return {
        ...state,
        loader_news: action.payload
      };
    default:
      return state;
  }
};
export default promotionReducers;
