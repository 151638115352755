const initialData = {
  users: [],
  newlist: [],
  getUsersLoader: false,
  addUserLoader: false,
  updateUserLoader: false
};

const userReducers = (state = initialData, action) => {
  switch (action.type) {
    case 'GET_ALL_USERS':
      return {
        ...state,
        users: action.payload
      };
    case 'CREATE_USER':
      return {
        ...state,
        newlist: [...state.list, action.payload]
      };
    case 'UPDATE_USER':
      return {
        ...state,
        newlist: [...state.list, action.payload]
      };

    case 'DELETE_USER':
      return {
        ...state,
        newlist: state.list.filter((elem) => elem.id !== action.id)
      };

    case 'GET_USERS_LOADER':
      return {
        ...state,
        getUsersLoader: action.payload
      };
    case 'ADD_USER_LOADER':
      return {
        ...state,
        addUserLoader: action.payload
      };
    case 'UPDATE_USER_LOADER':
      return {
        ...state,
        updateUserLoader: action.payload
      };

    default:
      return state;
  }
};
export default userReducers;
