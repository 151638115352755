import Repository from './Repository';
const GET_INGREDIENTS = '/ingredient';
const GET_INGREDIENTS_ALL = '/ingredient/all';

export default {
  getIngredients(id, artId) {
    return Repository.get(`${GET_INGREDIENTS}/${id}?sku=${artId}`);
  },
  getAllIngredients(id) {
    return Repository.get(`${GET_INGREDIENTS_ALL}/${id}`);
  }
};
