import Repository from './Repository';
const SESSION = '/table-sessions';
export default {
  createSessionRepo(payload) {
    return Repository.post(`${SESSION}/create-table`, payload);
  },
  updateSessionRepo(payload){
    return Repository.post(`${SESSION}/updateSessionCard`,payload)
  }
};
