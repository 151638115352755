/*!

=========================================================
* Vapiano Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Elktech (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Elktech

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Col,
  Spinner
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { login } from '../../store/actions/authAction';
import { setBusinesse, getBusinesses } from '../../store/actions/businessActions';
const Login = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [allBussiness, setAllBussiness] = useState([]);
  const [selectedBussines, setSelectedBussines] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(setBusinesse(selectedBussines));
    let obj = {
      email: username,
      password: password
    };
    await dispatch(login(obj));
    setLoading(false);
  };

  useEffect(() => {
    getAllBussiness();
  }, []);
  const getAllBussiness = () => {
    dispatch(getBusinesses()).then((resp) => {
      setAllBussiness(resp);
    });
  };
  const changBussinessHandler = (e) => {
    setSelectedBussines(e.target.value);
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="login-card px-lg-4 py-lg-4">
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <Input
                    required
                    type="select"
                    className="input-style"
                    name="select"
                    onChange={changBussinessHandler}
                  >
                    <option selected value="">
                      Select
                    </option>
                    {allBussiness?.map((item, key) => {
                      return (
                        <option key={key} value={item.blId}>
                          {item.blName}
                        </option>
                      );
                    })}
                  </Input>
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Email"
                    type="text"
                    autoComplete="new-email"
                    className="input-style"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Input
                    className="input-style"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <div className="custom-control custom-control-alternative custom-checkbox">
                <div className="btn-center float-right ">
                  <Button className="my-4" color="primary" type="submit">
                    {loading ? <Spinner size="sm" /> : <span>Sign in</span>}
                  </Button>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
