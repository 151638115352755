import { useState, useEffect, useRef } from 'react';
import NavItemComponent from 'components/NavItem';
import NavHeader from 'components/NavHeader';

const Sidebar = (props) => {
  const [activeChild, setActiveChild] = useState(-1);
  const [activeChildIndex, setActiveChildIndex] = useState(-1);
  // const [iconsidebar, setIconsidebar] = useState('');
  // const [finishStatus, setfinishStatus] = useState(false);
  const ref = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // useEffect(() => { //check if click is outside the submenu(to close it)
  // 	const checkIfClickedOutside = e => {
  // 		// If the menu is open and the clicked target is not within the menu,
  // 		// then close the menu
  // 		if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
  // 			setIsMenuOpen(false)
  // 			document.body.classList.remove('submenu-opened');
  // 		}
  // 	}

  // 	document.addEventListener("mousedown", checkIfClickedOutside)

  // 	return () => {
  // 		// Cleanup the event listener
  // 		document.removeEventListener("mousedown", checkIfClickedOutside)
  // 	}
  // }, [isMenuOpen])

  useEffect(() => {
    if (
      props.history.location.pathname == '/admin/stores/summary' &&
      document.body.classList.contains('submenu-opened')
    ) {
      document.body.classList.remove('submenu-opened');
    }
  }, [props.history.location.pathname]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    // if (!finishStatus) {
    // 	if (window.confirm('Do you want to go back ?')) {
    // 		setfinishStatus(!finishStatus);
    // if (window.innerWidth < 600) {
    window.location.reload();
    // 	alert(prop.children.length)
    // }
    // your logic
    // props.history.push('/');
    // } else {
    // 	window.history.pushState(null, null, window.location.pathname);
    // 	setfinishStatus(false);
    // }
    // }
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.isMenu != false)
        return (
          <NavItemComponent
            icon={prop.icon}
            icon_active={prop.icon_active || prop.icon}
            key={key}
            handleClick={
              prop.children && prop.children.length > 0
                ? async () => {
                    setIsMenuOpen(!isMenuOpen);
                    console.log('test');

                    setActiveChild(prop.children);
                    // setIconsidebar(prop.name);
                    await setActiveChildIndex(
                      activeChildIndex == key && document.body.classList.contains('submenu-opened')
                        ? -1
                        : key
                    );
                    document.body.classList.remove('sidebar-collapsed');
                    if (
                      activeChildIndex == key &&
                      document.body.classList.contains('submenu-opened')
                    ) {
                      document.body.classList.remove('submenu-opened');
                    } else {
                      document.body.classList.add('submenu-opened');
                    }
                  }
                : () => {
                    setActiveChild(-1);
                    setActiveChildIndex(-1);
                    // setIconsidebar(prop.name);
                    document.body.classList.remove('submenu-opened');
                    if (document.body.classList.contains('sidebar-collapsed')) {
                      document.body.classList.remove('sidebar-collapsed');
                    }
                  }
            }
            to={prop.layout + prop.path}
            text={prop.name}
            hasChildren={prop.children && prop.children.length > 0}
          />
        );
    });
  };
  const createChildLinks = (routes) => {
    let isHeaderAdded = false;
    return routes.map((prop, key) => {
      let header = routes.find((route) => route.type == 'header');
      if (header && isHeaderAdded == false) {
        isHeaderAdded = true;
        return <NavHeader icon={header.icon} text={header.name} key={key} />;
      }
      if (prop.isMenu != false && prop.type != 'header')
        return (
          <NavItemComponent
            icon={prop.icon}
            icon_active={prop.icon_active || prop.icon}
            key={key}
            to={prop.layout + prop.path}
            text={prop.name}
            hasChildren={prop.children && prop.children.length > 0}
            handleClick={() => {
              // setActiveChild(-1);
              // setActiveChildIndex(-1);
              // document.body.classList.remove('submenu-opened');
              if (window.innerWidth < 992) {
                document.body.classList.add('sidebar-collapsed');
              }
            }}
          />
        );
    });
  };
  const handleResize = () => {
    if (window.innerWidth < 992) {
      document.body.classList.add('sidebar-collapsed');
      document.body.classList.remove('submenu-opened');
    }
    // if (window.innerWidth < 2500) {
    // 	// document.body.classList.add('sidebar-collapsed');
    // 	document.body.classList.remove('submenu-opened');
    // }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div id="sidenav-main">
        {createLinks(props.routes)}{' '}
        <div className="sidenav-footer ">
          <img src="/img/operate_logo.png" alt="footer-title" />
        </div>
      </div>

      <div
        id="sidenav-child"
        className="active"
        ref={ref}
        // style={{ display: isMenuOpen ? "block" : "none" }}
        // style={{ visibility: isMenuOpen ? "visible" : "hidden" }}
      >
        {/* {isMenuOpen && */}
        {activeChild != -1 && createChildLinks(activeChild)}
      </div>
    </>
  );
};

export default Sidebar;
