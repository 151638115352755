import { RepositoryFactory } from 'repository/RepositoryFactory';
let SpecialSellerRepository = RepositoryFactory.get('specialseller');

export const getSpecialSellers = (payload) => async (dispatch) => {
  try {
    let { data } = await SpecialSellerRepository.getSpecialSellers(payload);

    if (data.success) {
      dispatch({
        type: 'GET_SPECIAL_SELLER',
        payload: data.data
      });
    }
  } catch (err) {
    console.log('error', err.message);
  }
};

export const removeSpecialSeller = (payload, blId) => async (dispatch) => {
  try {
    let res = await SpecialSellerRepository.removeSpecialSeller(payload);
    if (res?.data?.success) {
      dispatch(getSpecialSellers(blId));
      // alert('Deleted Successfully');
      return res?.data?.success;
    }
  } catch (err) {
    console.log('error', err.message);
  }
};

export const addSpecialSeller = (payload) => async (dispatch) => {
  try {
    let { data } = await SpecialSellerRepository.addSpecialSeller(payload);
    if (data.success) {
      dispatch(getSpecialSellers(payload.blId));
    }
  } catch (err) {
    console.log('error', err.message);
  }
};
