import Repository from './Repository';
const PROMOTION = '/business-details';
const HEADER = '/header';
const NEWS = '/news';

export default {
  getPopUp(id) {
    return Repository.get(`${PROMOTION}/${id}`);
  },
  addPopUp(payload) {
    console.log('Repo', payload);
    let form = new FormData();
    form.append('file', payload.file);
    form.append('popup', payload.popup);
    form.append('blId', payload.blId);
    return Repository.post(`${PROMOTION}`, form);
  },
  deletePopUp() {
    return Repository.delete(`${PROMOTION}/popup`);
  },
  updatePopUpURL(payload) {
    let form = new FormData();
    form.append('url', payload.url);
    return Repository.put(`${PROMOTION}/${payload.id}`, form);
  },
  getHeader(id) {
    return Repository.get(`${HEADER}/${id}`);
  },
  addHeader(payload) {
    let form = new FormData();
    form.append('file', payload.file);
    form.append('blId', payload.blId);
    return Repository.post(`${HEADER}`, form);
  },
  deleteHeader(_id) {
    return Repository.delete(`${HEADER}/${_id}`);
  },
  updateHeaderURL(payload) {
    let form = new FormData();
    form.append('url', payload.url);
    return Repository.put(`${HEADER}/${payload.id}`, form);
  },
  getNews(id) {
    return Repository.get(`${NEWS}/${id}`);
  },
  addNews(payload) {
    let form = new FormData();
    form.append('file', payload.file);
    form.append('blId', payload.blId);
    return Repository.post(`${NEWS}`, form);
  },
  deleteNews(_id) {
    return Repository.delete(`${NEWS}/${_id}`);
  },
  updateNewsURL(payload) {
    let form = new FormData();
    form.append('url', payload.url);
    return Repository.put(`${NEWS}/${payload.id}`, form);
  }
};
