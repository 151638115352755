import Repository from './Repository';
const SPECIAL = '/special';
export default {
  getSpecialSellers(payload) {
    // console.log("repo best seeleer ddata" )
    return Repository.get(`${SPECIAL}/${payload}`);
  },
  addSpecialSeller(payload) {
    return Repository.post(`${SPECIAL}`, payload);
  },
  removeSpecialSeller(payload) {
    return Repository.delete(`${SPECIAL}/${payload}`);
  }
};
