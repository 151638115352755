import { RepositoryFactory } from '../../repository/RepositoryFactory';
var StationsRepository = RepositoryFactory.get('stations');

export const getAllStations = () => async (dispatch) => {
  try {
    let { data } = await StationsRepository.getAllStations();

    dispatch({
      type: 'GET_ALL_STATIONS',
      payload: data.data
    });
  } catch (err) {
    console.log('error', err.message);
  }
};
export const createStations = (payload) => async (dispatch) => {
  try {
    await StationsRepository.createStations(payload);
    dispatch(getAllStations());
  } catch (err) {
    console.log('error', err.message);
  }
};
export const updateStations = (obj) => async (dispatch) => {
  try {
    let res = await StationsRepository.updateStations(obj);
    //   console.log("item deleted");
    if (res?.data?.success) {
      // alert('Station Updated');
      dispatch(getAllStations());
      return res?.data?.success;
    }
  } catch (err) {
    console.log('error', err.message);
  }
};
export const deleteStations = (_id) => async (dispatch) => {
  try {
     await StationsRepository.deleteStations(_id);
    dispatch(getAllStations());
    return true
  } catch (err) {
    console.log('error', err?.response);
    return err?.response?.data?.message;
  }
};
