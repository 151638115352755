import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
// import { getBusinessSettings } from "store/actions/settingsAction";
import { updateRightIcons } from 'store/actions/settingsAction';
import AppIconUploadCom from './AppIconUploadCom';


const AppIconUpload = ({ businessID }) => {
  const dispatch = useDispatch();

  let storeType = null;
  let pageLayoutSettings = useSelector((state) => state?.settings?.allSettings?.page_layout);
  const [currentSettings, setCurrentSettings] = useState('');

  if (window.location.pathname === '/store/takeaway-delivery/settings') {
    storeType = 'takeaway_delivery';
    // currentSettings = currentSettings?.takeaway_delivery;
  } else if (window.location.pathname === '/store/in-store/settings') {
    storeType = 'instore';
    // currentSettings = currentSettings?.instore;
  } else if (window.location.pathname === '/store/kiosk/settings') {
    storeType = 'kiosk';
    // currentSettings = currentSettings?.kiosk;
  }

  useEffect(() => {
    if (storeType === 'instore') setCurrentSettings(pageLayoutSettings?.instore);
    else if (storeType === 'kiosk') setCurrentSettings(pageLayoutSettings?.kiosk);
    else if (storeType === 'takeaway_delivery')
      setCurrentSettings(pageLayoutSettings?.takeaway_delivery);
  }, [storeType, pageLayoutSettings]);

  const [iconLoading, setIconLoading] = useState({
    bill: false,
    cart: false,
    cartModal:false,
    guest_wall: false,
    service: false,
    footer: false,
    menu: false
  });
  const [iconSave, setIconSave] = useState({
    bill: false,
    cart: false,
    cartModal:false,
    guest_wall: false,
    service: false,
    footer: false,
    menu: false
  });
  const [iconUploads, setIconUploads] = useState({
    bill: null,
    cart: null,
    cartModal:null,
    guest_wall: null,
    service: null,
    footer: null,
    menu: null
  });

  const getImageInput = async (e) => {
    console.log(e.target.name);
    let iconName = await e.target.name;

    // console.log(e);

    if (iconName === 'footer') {
      setIconLoading({ ...iconLoading, footer: true });
    } else if (iconName === 'bill') {
      setIconLoading({ ...iconLoading, bill: true });
    } else if (iconName === 'service') {
      setIconLoading({ ...iconLoading, service: true });
    } else if (iconName === 'cart') {
      setIconLoading({ ...iconLoading, cart: true });
    }else if (iconName === 'cartModal') {
      setIconLoading({ ...iconLoading, cartModal: true });
    } else if (iconName === 'guest_wall') {
      setIconLoading({ ...iconLoading, guest_wall: true });
    } else if (iconName === 'menu') {
      setIconLoading({ ...iconLoading, menu: true });
    }

    if (!e) {
      document.getElementById('ImageInput').click();
      console.log('not an event');
    } else {
      // console.log("its an event");
      if (e.target.files && e.target.files[0]) {
        let img = await e.target.files[0];
        // let reader = await new FileReader();

        if (iconName === 'footer') {
          setIconUploads({ ...iconUploads, footer: img });
          setIconLoading({ ...iconLoading, footer: false });
        } else if (iconName === 'bill') {
          setIconUploads({ ...iconUploads, bill: img });
          setIconLoading({ ...iconLoading, bill: false });
        } else if (iconName === 'service') {
          setIconUploads({ ...iconUploads, service: img });
          setIconLoading({ ...iconLoading, service: false });
        } else if (iconName === 'cart') {
          setIconUploads({ ...iconUploads, cart: img });
          setIconLoading({ ...iconLoading, cart: false });
        } else if (iconName === 'cartModal') {
          setIconUploads({ ...iconUploads, cartModal: img });
          setIconLoading({ ...iconLoading, cartModal: false });
        } else if (iconName === 'guest_wall') {
          setIconUploads({ ...iconUploads, guest_wall: img });
          setIconLoading({ ...iconLoading, guest_wall: false });
        } else if (iconName === 'menu') {
          setIconUploads({ ...iconUploads, menu: img });
          setIconLoading({ ...iconLoading, menu: false });
        }
        // reader.onload = (e) => {
        //   if (e !== null) {
        //     console.log("loading reader");
        //      Icon = reader.result;
        //     console.log(Icon, "Icon");
        //     // setIconUploads({...iconUploads, footer : Icon})
        //     // setIconUploads({...iconUploads, [e.target.name] : Icon})

        //     console.log("test", iconName);

        //   }

        //   console.log(reader.result, "reader.result");
        // };
        // console.log(reader.readAsDataURL(img), "img");
        // console.log(reader.result, "img");
        // console.log(Icon, "Icon");
      }
    }

    // setTimeout(() => {
    //   console.log(iconUploads, "iconUploads");
    // }, 5000);
  };

  const handleIconUploads = async (e) => {
    console.log(e,"the event ")
    let iconName = await e.target.name;

    // console.log(e.target.name, "iconName");

    if (iconName === 'footer') {
      setIconSave({ ...iconSave, footer: true });
    } else if (iconName === 'bill') {
      setIconSave({ ...iconSave, bill: true });
    } else if (iconName === 'service') {
      setIconSave({ ...iconSave, service: true });
    } else if (iconName === 'cart') {
      setIconSave({ ...iconSave, cart: true });
    } else if (iconName === 'cartModal') {
      setIconSave({ ...iconSave, cartModal: true });
    } else if (iconName === 'guest_wall') {
      setIconSave({ ...iconSave, guest_wall: true });
    } else if (iconName === 'menu') {
      setIconSave({ ...iconSave, menu: true });
    }

    const iconPayload = {
      blId: businessID,
      type: storeType.toString(),
      sub_page: iconName.toString(),
      file: null
    };

    if (iconName === 'bill') {
      iconPayload.file = await iconUploads.bill;
      setIconSave({ ...iconSave, bill: false });
    } else if (iconName === 'service') {
      iconPayload.file = await iconUploads.service;
      setIconSave({ ...iconSave, service: false });
    } else if (iconName === 'cart') {
      iconPayload.file = await iconUploads.cart;
      setIconSave({ ...iconSave, cart: false });
    } else if (iconName === 'cartModal') {
      iconPayload.file = await iconUploads.cartModal;
      setIconSave({ ...iconSave, cartModal: false });
    } else if (iconName === 'guest_wall') {
      iconPayload.file = await iconUploads.guest_wall;
      setIconSave({ ...iconSave, guest_wall: false });
    } else if (iconName === 'footer') {
      iconPayload.file = await iconUploads.footer;
      setIconSave({ ...iconSave, footer: false });
    } else if (iconName === 'menu') {
      iconPayload.file = await iconUploads.menu;
      setIconSave({ ...iconSave, menu: false });
    } else if (iconName === 'menu_top_icon') {
      iconPayload.file = await iconUploads.menu_top_icon;
      setIconSave({ ...iconSave, menu_top_icon: false });
    }

    // setTimeout(() => {
    // console.log(iconPayload, "handleIconUploads");
    if(!e.target.value){
    dispatch(updateRightIcons(iconPayload));

    }
    // dispatch(getBusinessSettings({
    //   buid: businessID,
    //   table_no: businessID === 660076343853236 ? 20 : null, //no table_no for Boname
    // }))
    // }, 3000);
  };

  return (
    <>
      {/*================ Menu Top Kiosk Settings========================== */}
      {window.location.pathname === '/store/kiosk/settings' ? (
        <Col xs="12">
          <div className="shadow bg-white rounded mt-5">
            <div className="wrapper p-3 ">
              <div className="title d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <img src="/suitcase.png" alt="clock" height="20px" className="mr-3" />

                  <span>
                    <h4 className="mb-0 text-black">
                      {' '}
                      Top Kiosk Icon{' '}
                      {(pageLayoutSettings?.menu_top_icon !== '' ||
                        pageLayoutSettings?.menu_top_icon !== undefined) && (
                        <img
                          alt="menu top icon"
                          width={60}
                          height={50}
                          src={pageLayoutSettings?.menu_top_icon}
                        />
                      )}
                    </h4>
                  </span>
                </div>
                <div className="d-flex ml-auto">
                  {iconSave.menu_top_icon ? (
                    <span
                      className="spinner-border spinner-border-lg text-info text-center"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    <Button
                      disabled={iconUploads.menu_top_icon !== null || undefined ? false : true}
                      color="primary"
                      className="text-uppercase px-4"
                      name="menu_top_icon"
                      onClick={(e) => handleIconUploads(e)}>
                      Save
                    </Button>
                  )}
                </div>
              </div>
              <Row className="mt-3">
                <Col md="12" className="mt-4 mt-md-0">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex flex-column flex-shrink-0">
                        <small className="font-weight-semi-bold flex-shrink-0">Background</small>
                        <small className="font-weight-semi-bold flex-shrink-0">Max 195x67</small>
                      </div>
                      <div className="w-100 border ml-4 p-2">
                        <span className="text-muted d-flex align-items-center pl-4">
                          {iconLoading.menu_top_icon ? (
                            <span
                              className="spinner-border spinner-border-lg text-info text-center"
                              role="status"
                              aria-hidden="true"></span>
                          ) : (
                            <label className="custom-file-upload w-100" htmlFor="menu_top_icon">
                              <input
                                disabled={iconLoading.menu_top_icon}
                                type="file"
                                className="w-100"
                                alt="image_upload"
                                id="menu_top_icon"
                                name="menu_top_icon"
                                placeholder="bkiljkl"
                                onChange={(e) => getImageInput(e)}
                                hidden={true}
                              />
                              {iconUploads.menu_top_icon === null || undefined
                                ? 'Update Image'
                                : `Image Selected: ${
                                    iconUploads.menu_top_icon !== null || undefined
                                      ? iconUploads?.menu_top_icon?.name
                                      : 'Uknown'
                                  }`}
                              <i className="fa fa-plus ml-3 "></i>
                            </label>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      ) : (
        
        <>
       {/* ================Special Icon============================= */}
          <AppIconUploadCom
            textLabel={'Special Icon'}
            resolution={'85x85'}
            currentSettings={currentSettings?.menu?.right_icon_url}
            iconUploads={iconUploads?.menu}
            iconLoading={iconLoading?.menu}
            iconSave={iconSave?.menu}
            getImageInput={getImageInput}
            handleIconUploads={handleIconUploads}
            id={'menu'}
            name={'menu'}
          />
          {/*================ Bill Page Icon ========================== */}
          <AppIconUploadCom
            textLabel={' Bill Page Icon'}
            resolution={'85x85'}
            currentSettings={currentSettings?.bill?.right_icon_url}
            iconUploads={iconUploads?.bill}
            iconLoading={iconLoading?.bill}
            iconSave={iconSave?.bill}
            getImageInput={getImageInput}
            handleIconUploads={handleIconUploads}
            id={'bill'}
            name={'bill'}
          />
          {/*================ Cart Heading ========================== */}
          <AppIconUploadCom
            textLabel={'Cart Page Icon'}
            resolution={'85x85'}
            currentSettings={currentSettings?.cart?.right_icon_url}
            iconUploads={iconUploads?.cart}
            iconLoading={iconLoading?.cart}
            iconSave={iconSave?.cart}
            getImageInput={getImageInput}
            handleIconUploads={handleIconUploads}
            id={'cart'}
            name={'cart'}
          />
          {/*================ Cart ADD Modal Icon ========================== */}
          <AppIconUploadCom
            textLabel={' Cart Pop Up Box Icon'}
            resolution={'70x52'}
            currentSettings={currentSettings?.cart?.cart_modal_icon_url}
            iconUploads={iconUploads?.cartModal}
            iconLoading={iconLoading?.cartModal}
            iconSave={iconSave?.cartModal}
            getImageInput={getImageInput}
            handleIconUploads={handleIconUploads}
            name={'cartModal'}
            id={'cartModal'}
          />
          {/*================ Wall of fame Page Heading ========================== */}

          <AppIconUploadCom
            textLabel={'Wall of Fame Page Icon'}
            resolution={'70x52'}
            currentSettings={currentSettings?.guest_wall?.right_icon_url}
            iconUploads={iconUploads?.guest_wall}
            iconLoading={iconLoading.guest_wall}
            iconSave={iconSave.guest_wall}
            getImageInput={getImageInput}
            handleIconUploads={handleIconUploads}
            name={'guest_wall'}
            id={'guest_wall'}
          />
          {/*================ Service Page Heading ========================== */}

          <AppIconUploadCom
            textLabel={' Service Page Icon'}
            resolution={'60x152'}
            currentSettings={currentSettings?.service?.right_icon_url}
            iconUploads={iconUploads?.service}
            iconLoading={iconLoading.service}
            iconSave={iconSave.service}
            getImageInput={getImageInput}
            handleIconUploads={handleIconUploads}
            name={'service'}
            id={'service'}
          />
          {/*================ Footer Settings========================== */}
          <AppIconUploadCom
            textLabel={'Footer Icon'}
            resolution={'195x67'}
            currentSettings={currentSettings?.footer?.right_icon_url}
            iconUploads={iconUploads?.footer}
            handleIconUploads={handleIconUploads}
            iconLoading={iconLoading.footer}
            getImageInput={getImageInput}
            iconSave={iconSave.footer}
            name={'footer'}
            id={'footer'}
          />
        
        </>
      )}
    </>
  );
};

export default AppIconUpload;
