/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import ErrorModal from './Basic Modal/ErrorModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Table, FormGroup, Badge, Button, Spinner, Collapse, InputGroup } from 'reactstrap';
import { addMenuDetailsInArticle } from 'store/actions/article_detailsAction';
import { addPriority } from 'store/actions/menuActions';
import { addMenu } from 'store/actions/menuActions';
import { deleteMenu } from 'store/actions/menuActions';
import { RepositoryFactory } from '../repository/RepositoryFactory';
import { getBusinesseMenus, setBusinesseMenu } from 'store/actions/businessActions';
import ReorderDialog from './Dialogs/ReorderDialog';
import axios from 'axios';
import { absoluteUrl } from 'utils/backendUrl';
import { useQuery } from '@tanstack/react-query';
import { compareDesc, parse } from 'date-fns';
import { Eye } from 'lucide-react';
import TimerModal from './Basic Modal/TimerModal';
// import { updateposition } from 'store/actions/menuActions';
import { updateGroupposition } from 'store/actions/menuActions';

function MenuSection(props) {
  const {
    menuAddLoader,
    getAlleArtikelenLoader,
    menus,
    takeAwaymenus,
    alleArtikelen,
    kioskMenus,
  } = useSelector((state) => state.menu);
  const group  =useSelector((state)=>state.menu.group)
  const bussinessId = sessionStorage.getItem('blId');
  const { blId, bussines_menus } = useSelector((state) => state.business);
  let [activeMenus, setActiveMenus] = useState([]);
  const [searchItem, setSearchItem] = useState('');
  const [counterMenu, setCounterMenu] = useState([]);
  const [checkboxData, setCheckboxData] = useState(['name', 'price', 'sku', 'menu_tags']);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [priorityLoaderItems, setPriorityLoaderItems] = useState({})
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  let UsersRepository = RepositoryFactory.get('sync_data');

  const menuObj = {
    'In Store': menus,
    'Takeaway & Delivery': takeAwaymenus,
    'Kiosk-Menu': kioskMenus
  };

  async function getAllMenus(blId) {
    try {
      const menus = await axios.get(absoluteUrl(`/menu_v2/home-menus/${blId}`));
      return menus.data.data;
    } catch (error) {
      console.log('GET_MENUS_ERROR', error);
    }
  }

  // async function getAllGroups(blId) {
  //   try {
  //     const groups = await axios.get(absoluteUrl(`/menu_v2/groups/backoffice/${blId}`));
  //     return groups.data.data;
  //   } catch (error) {
  //     console.error('ERROR_FETCHING_GROUPS', error);
  //   }
  // }
  async function getAllGroups(blId) {
    try {
      const groups = await axios.get(absoluteUrl(`/group-logo/${parseInt(blId, 10)}`));
      return groups.data;
    } catch (error) {
      console.error('ERROR_FETCHING_GROUPS', error);
    }
  }

  const toggle = () => setModal(!modal);
  // get menus and save them as server state
  const { data: allMenus, isLoading: isMenusLoading } = useQuery({
    queryKey: ['allmenus', blId],
    queryFn: () => getAllMenus(blId)
  });

  // generate groups from fetched menus on the client
  // " not a bad practice, rework should be done on the server"
  const { data: allGroups, isLoading: isGroupsLoading } = useQuery({
    queryKey: ['allgroups', blId],
    queryFn: () => getAllGroups(blId)
  });

  // console.log('allgroups ', allGroups)
  // console.log('allmenus ', allMenus)
  function compareDate(firstDate, secondDate) {

    if (compareDesc(firstDate, secondDate) == -1) return false;
    else return true;
  }
  function filterVisibleGroups(group) {
    const startTime = parse(group.startTime, 'HH:mm', new Date());
    const endTime = parse(group.endTime, 'HH:mm', new Date());
    if (typeof group.visible === 'undefined' || group.visible === null) {
      return true;
    }
    if (!group.active && group.visible) return true;
    if (group.active && group.visible && compareDate(new Date(), endTime) && compareDate(startTime, new Date()))
      return true;
    return false;
  }

  const createTimeSlots = (fromTime, toTime) => {
    let startTime = moment(fromTime, 'HH:mm');
    let endTime = moment(toTime, 'HH:mm');
    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }
  };
  React.useEffect(() => {
    createTimeSlots('08:00', '10:00');
  }, []);



  const groupData = (dataList) => {
    let obj = {};
    dataList?.forEach((data) => {
      // const check = results.find((el) => el.id === data.id);
      if (!obj[data.group]) {
        let temp = {
          group: data.group,
          id: data.id,
          position: data.position,
          items: []
        };
        obj[data.group] = temp;
      }
      obj[data.group].items.push(data);
    });
    return sortByPosition(obj);
  };

  const sortByPosition = (obj) => {
    return Object.values(obj).sort((a, b) => {
      if (!b.position) {
        return -1;
      }
      return a.position - b.position;
    });
  };

  // const groupMenus = () => {
  //   if (!menus.length) return [];
  //   const menuGroup = groupData(menus);
  //   return menuGroup;
  // };

  const getFilteredAlleArtikelen = (items) => {
    let data = [];

    data =
      items &&
      items.filter((item) => {
        return !menuObj[props.menus]?.find((el) => {
          return el.sku === item.sku;
        });
      });
    return data;
  };

  // const collapseAll = () => {
  //   setActiveMenus([]);
  // };
  const isMenuActive = (idx) => {
    return activeMenus.indexOf(idx) != -1;
  };
  const toggleMenuActive = (idx) => {
    setActiveMenus((prevProp) => {
      let articleIdx = activeMenus.indexOf(idx);

      if (articleIdx == -1) {
        prevProp.push(idx);
        return [...prevProp];
      } else {
        prevProp.splice(articleIdx, 1);
        return [...prevProp];
      }
    });
  };


  const handleCheckboxChange = (name) => {
    let newArray = [...checkboxData, name];
    if (checkboxData.includes(name)) {
      newArray = newArray.filter((n) => n != name);
    }
    setCheckboxData(newArray);
  };

  const [groupid, setGroupId] = useState();
  const [thisGroup, setThisGroup] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  async function test(goupid) {
    const blId = goupid.blId;
    // console.log(blId)
    try {
      const menus = await axios.get(absoluteUrl(`/group-logo/timegroups/${blId}/${goupid.id}`));
      if (menus.data.data.length !== 0) {
        setStart(menus.data.data[0].startTime);
        setEnd(menus.data.data[0].endTime);
      }
    } catch (error) {
      console.log('GET_MENUS_ERROR', error);
    }
    setGroupId(goupid.id);
    toggle();
  }


  const syncDataHandler = () => {
    setLoading(true);
    var selectedID = localStorage.getItem('selectedID');
    UsersRepository.syncData(bussinessId,selectedID)
      .then((resp) => {
        setLoading(false);
        if (resp?.status === 200) {
          // alert('Sync Successfully');
          localStorage.removeItem('selectedID');
          setShowErrorMsg('Sync In Process');
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorMsg('Sync Successfully');
          }, 10000);
        } else {
          // alert('Error Occured While Syncing Data');
          setShowErrorMsg('Error Occured While Syncing Data');
          setShowErrorModal(true);
        }
      })
      .catch((error) => {
        // alert('Error Occured While Syncing Data');
        setShowErrorMsg('Error Occured While Syncing Data');
        setShowErrorModal(true);
        setLoading(false);
        console.log(error);
      });
  };
  const updateCounter = (idx, blId, itemID, item) => {
    setPriorityLoaderItems((prevItems) => {
      const updatedItems = { ...prevItems, [itemID]: true };
      return updatedItems;
    });
    dispatch(
      addPriority({
        priority: counterMenu[idx] ? counterMenu[idx] : item?.priority?.toString(),
        blId: blId,
        itemID: itemID
      })
    ).then(() => {
      setPriorityLoaderItems((prevItems) => {
        const updatedItems = { ...prevItems };
        delete updatedItems[itemID];
        return updatedItems;
      });
    });
  };
  const handleChange = (e, index) => {
    const list = [...counterMenu];
    list[index] = e.target.value;
    setCounterMenu(list);
  };

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const data = groupData(menuObj[props.menus]);
    setGroups(data);
  }, [menuObj[props.menus]]);
  useEffect(() => {
    if (blId) {
      dispatch(getBusinesseMenus(blId)).then((resp) => {
        if (resp.success) {
          dispatch(setBusinesseMenu(resp?.data[0]?.ikentooMenuId));
        }
      });
    }
  }, [blId]);
  const bussinessMenusChangeHandler = (id) => {
    dispatch(setBusinesseMenu(id));
  };
  useEffect(() => {
    if (bussines_menus.length && bussines_menus[0]?.ikentooMenuId) {
      bussinessMenusChangeHandler(bussines_menus[0]?.ikentooMenuId);
      localStorage.setItem('selectedID', bussines_menus[0]?.ikentooMenuId);
    }
  }, [bussines_menus]);

  // reorder state
  const [configModal, setConfigModal] = useState(false)
  // reorder handlers
  const openConfigurationModal = useCallback(function openConfModal() {
    setConfigModal(true);
  }, [configModal])
  const closeConfigurationModal = useCallback(function openConfModal() {
    setConfigModal(false);
  }, [configModal])



  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title">{props.title}</h5>
          {props.isSync ? (
            <div className='flex gap-4'>
              <Button disabled={loading}
                onClick={() => {
                  dispatch(updateGroupposition(blId))
                  //dispatch(updateposition(blId));
                  openConfigurationModal()

                }}  >
                Configure
              </Button>
              <Button disabled={loading} onClick={syncDataHandler}>
                {loading ? (
                  <span
                    className="spinner-border spinner-border-lg text-danger text-center"
                    role="status"
                    aria-hidden="true"></span>
                ) : (
                  'Sync Now'
                )}
              </Button>
            </div>
          ) : (
            <InputGroup className="input-group-alternative" style={{ width: '30%' }}>
              <Input
                required
                type="select"
                onChange={(e) => {
                  localStorage.setItem('selectedID', e.target.value);
                  bussinessMenusChangeHandler(e.target.value);
                }}
                name="select">
                {bussines_menus?.map((item, key) => {
                  return (
                    <option value={item.ikentooMenuId} key={key}>
                      {item.menuName}
                    </option>
                  );
                })}
              </Input>
            </InputGroup>
          )}
        </div>
        <div className="card-body mt-2 p-2">
          <Input type="text" placeholder="Search" onChange={(e) => setSearchItem(e.target.value)} />
          <Table borderless responsive size="sm">
            <thead>
              <tr>
                <td className="text-center">NAME</td>
                <td className="text-center">PRICE</td>
                <td className="text-center">SKU</td>
                <td className="text-center">MENUTAGS</td>
                {props.isSync && <td className="text-center">Priorities</td>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center pl-3">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={checkboxData.includes('name')}
                      onChange={() => handleCheckboxChange('name')}
                    />
                  </FormGroup>
                </td>
                <td className="text-center pl-3">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={checkboxData.includes('price')}
                      onChange={() => handleCheckboxChange('price')}
                    />
                  </FormGroup>
                </td>
                <td className="text-center">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={checkboxData.includes('sku')}
                      onChange={() => handleCheckboxChange('sku')}
                    />
                  </FormGroup>
                </td>
                <td className="text-center">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={checkboxData.includes('menu_tags')}
                      onChange={() => handleCheckboxChange('menu_tags')}
                    />
                  </FormGroup>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div className="mt-3">
          <DragDropContext>
            <Droppable droppableId="droppableArticle">
              {(provided) => (
                <div ref={provided.innerRef}>
                  {props.title === 'Alle Artikelen' ? (
                    <>
                      {getAlleArtikelenLoader ? (
                        <Spinner className="d-flex mx-auto mt-5" size="lg" color="primary" />
                      ) : (
                        <>
                          {alleArtikelen ? (
                            <>
                              {alleArtikelen &&
                                alleArtikelen.map((menu, k) => {
                                  return (
                                    <>
                                      <>
                                        <div
                                          className="d-flex align-items-center p-3"
                                          onClick={() => toggleMenuActive(k)}>
                                          <h6 className="ml-3 mt-1">{menu.group}</h6>

                                          {isMenuActive(k) ? (
                                            <i
                                              style={{ marginLeft: 'auto' }}
                                              className="fa fa-angle-up"></i>
                                          ) : (
                                            <i
                                              style={{ marginLeft: 'auto' }}
                                              className="fa fa-angle-down"></i>
                                          )}
                                        </div>

                                        <Table responsive>
                                          <Collapse
                                            style={{
                                              display: isMenuActive(k) ? 'contents' : 'none'
                                            }}
                                            isOpen={isMenuActive(k)}>
                                            <thead>
                                              <tr
                                                onClick={() => toggleMenuActive(k)}
                                                className="font-weight-bold">
                                                <th></th>
                                                {checkboxData.includes('name') ? <th>Name</th> : ''}
                                                {checkboxData.includes('price') ? (
                                                  <th>price</th>
                                                ) : (
                                                  ''
                                                )}
                                                {checkboxData.includes('sku') ? <th>sku</th> : ''}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {getFilteredAlleArtikelen(menu.items)
                                                .filter((menu) => {
                                                  if (searchItem === '') {
                                                    return menu;
                                                  } else if (
                                                    menu.productName &&
                                                    menu.productName
                                                      .toLowerCase()
                                                      .includes(searchItem.toLowerCase())
                                                  ) {
                                                    return menu;
                                                  }
                                                })
                                                .map((data, idx) => {
                                                  return (
                                                    <tr key={idx}>
                                                      <td>
                                                        <Button
                                                          onClick={() => {
                                                            let article_detail = {
                                                              blId: blId,
                                                              article_id: data.sku,
                                                              menus: [props.menu_tag]
                                                            };
                                                            let obj = {
                                                              blId: blId,
                                                              group: menu.group,
                                                              id: menu.id,
                                                              name: data.productName,
                                                              price: data.productPrice
                                                                ? data.productPrice
                                                                : 0,
                                                              sku: data.sku,
                                                              priority: data?.priority,
                                                              menu_tags: props.menu_tag
                                                            };
                                                            dispatch(addMenu(obj));
                                                            dispatch(
                                                              addMenuDetailsInArticle(
                                                                article_detail
                                                              )
                                                            );
                                                          }}
                                                          color="primary"
                                                          size="sm">
                                                          {menuAddLoader ? (
                                                            <Spinner size="sm" />
                                                          ) : (
                                                            <i
                                                              className="fa fa-plus-circle"
                                                              aria-hidden="true"></i>
                                                          )}
                                                        </Button>
                                                      </td>
                                                      {checkboxData.includes('name') ? (
                                                        <td>{data.productName}</td>
                                                      ) : (
                                                        ''
                                                      )}
                                                      {checkboxData.includes('price') ? (
                                                        <td>
                                                          {data.productPrice
                                                            ? data.productPrice
                                                            : 0}
                                                        </td>
                                                      ) : (
                                                        ''
                                                      )}
                                                      {checkboxData.includes('sku') ? (
                                                        <td>{data.sku}</td>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </tr>
                                                  );
                                                })}
                                            </tbody>{' '}
                                          </Collapse>
                                        </Table>
                                      </>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <div className="p-5"></div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {isGroupsLoading ? (
                        <Spinner className="d-flex mx-auto mt-5" size="lg" color="primary" />
                      ) : (
                        <>
                          {menus && group ? (
                            <>
                              {group.map((data, k) => {
                                return (
                                  <>
                                    <Draggable
                                      key={k}
                                      draggableId={k.toString()}
                                      index={k}
                                      isDragDisabled={true}
                                    >
                                      {(provided) => (
                                        <>
                                          <div
                                            onClick={(e) => {
                                              e.preventDefault();
                                              toggleMenuActive(k);
                                            }}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center',
                                              paddingTop: '1.25rem',
                                              paddingBottom: '1.25rem',
                                              paddingLeft: '2rem',
                                              paddingRight: '2rem'
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: '1rem',
                                                fontWeight: '600'
                                              }}
                                            >
                                              {data.group}
                                            </span>
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent: 'space-evenly',
                                                alignItems: 'center',
                                                gap: '4px'
                                              }}
                                            >
                                              {/* <Tooltip title="Visibility" placement="top"> */}
                                              <button
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  test(data);
                                                  setThisGroup(data);
                                                }}
                                                style={{
                                                  border: 'none',
                                                  padding: '2px 2px',
                                                  transition: 'background-color 0.3s ease-in-out',
                                                  borderRadius: '50%',
                                                  backgroundColor: 'transparent',
                                                  ':hover': {
                                                    backgroundColor: '#ccc'
                                                  }
                                                }}
                                              >
                                                <Eye
                                                  color={
                                                    filterVisibleGroups(data) ? 'green' : 'gray'
                                                  }
                                                />
                                              </button>
                                              {/* </Tooltip> */}
                                              <div>
                                                {isMenuActive(k) ? (
                                                  <i
                                                    className="fa fa-angle-up"
                                                    onClick={(e) => e.stopPropagation()}
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-angle-down"
                                                    onClick={(e) => e.stopPropagation()}
                                                  ></i>
                                                )}
                                              </div>
                                            </div>
                                          </div>

                                          <Table responsive>
                                            <Collapse
                                              style={{
                                                display: isMenuActive(k) ? 'contents' : 'none'
                                              }}
                                              isOpen={isMenuActive(k)}
                                            >
                                              <thead>
                                                <tr
                                                  onClick={() => toggleMenuActive(k)}
                                                  className="font-weight-bold"
                                                >
                                                  <th></th>
                                                  {checkboxData.includes('name') ? (
                                                    <th>Name</th>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {checkboxData.includes('price') ? (
                                                    <th>price</th>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {checkboxData.includes('sku') ? <th>sku</th> : ''}
                                                  {checkboxData.includes('menu_tags') ? (
                                                    <th>MenuTags</th>
                                                  ) : (
                                                    ''
                                                  )}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {menus
                                                  .filter(
                                                    (menu) => String(menu.id) == String(data.id) || String(menu.group) == String(data.group)
                                                  )
                                                  .map((item, idx) => {
                                                    return (
                                                      <tr key={idx}>
                                                        <td>
                                                          <Button
                                                            onClick={() => {
                                                              let article_detail = {
                                                                blId: blId,
                                                                article_id: item.sku,
                                                                menus: [props.menu_tag]
                                                              };
                                                              dispatch(
                                                                deleteMenu(item._id, item.blId)
                                                              );
                                                              dispatch(
                                                                addMenuDetailsInArticle(
                                                                  article_detail
                                                                )
                                                              );
                                                            }}
                                                            color="danger"
                                                            size="sm"
                                                          >
                                                            {menuAddLoader ? (
                                                              <Spinner size="sm" />
                                                            ) : (
                                                              <i
                                                                className="fa fa-minus-circle"
                                                                aria-hidden="true"
                                                              ></i>
                                                            )}
                                                          </Button>
                                                        </td>
                                                        {checkboxData.includes('name') ? (
                                                          <td>{item.name}</td>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {checkboxData.includes('price') ? (
                                                          <td>{item.price}</td>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {checkboxData.includes('sku') ? (
                                                          <td>{item.sku}</td>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {checkboxData.includes('menu_tags') ? (
                                                          <td>
                                                            {item.menu_tags === 'In Store' ? (
                                                              <Badge
                                                                pill
                                                                className="border border-danger text-danger bg-transparent"
                                                              >
                                                                IS
                                                              </Badge>
                                                            ) : (
                                                              <Badge
                                                                pill
                                                                className="border border-danger text-danger bg-transparent"
                                                              >
                                                                TA
                                                              </Badge>
                                                            )}
                                                          </td>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {props.isSync && (
                                                          <td>
                                                            <input
                                                              style={{
                                                                height: '36px',
                                                                maxWidth: '65px'
                                                              }}
                                                              type="number"
                                                              placeholder="priority"
                                                              value={
                                                                counterMenu[idx] || item?.priority
                                                              }
                                                              onChange={(e) => handleChange(e, idx)}
                                                            />
                                                            <Button
                                                              color="primary"
                                                              className="mx-2"
                                                              onClick={() => {
                                                                updateCounter(
                                                                  idx,
                                                                  item?.blId,
                                                                  item?._id,
                                                                  item
                                                                );
                                                              }}
                                                            >
                                                              {priorityLoaderItems[item?._id] ? (
                                                                <span
                                                                  className="spinner-border spinner-border-lg text-danger text-center"
                                                                  role="status"
                                                                  aria-hidden="true"
                                                                ></span>
                                                              ) : (
                                                                'Save'
                                                              )}
                                                            </Button>
                                                          </td>
                                                        )}
                                                      </tr>
                                                    );
                                                  })}
                                              </tbody>
                                            </Collapse>
                                          </Table>
                                        </>
                                      )}
                                    </Draggable>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <div className="p-5"></div>
                          )}

                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <ErrorModal
        display={showErrorModal}
        size="lg"
        center="true"
        handleClose={() => {
          setShowErrorModal(false);
        }}
        footer={true}
        content={
          <>
            <h1 className="text-center">{showErrorMsg}</h1>
          </>
        }
      />

      <TimerModal
        modal={modal}
        toggle={toggle}
        groupid={groupid}
        group={thisGroup}
        blId={blId}
        start={start}
        end={end}
      />

      {/* handle articles reordering */}
      <ReorderDialog
        allMenus={allMenus}
        isConfigModalOpen={configModal}
        handleCloseConfigModal={closeConfigurationModal}
      />
    </>
  );
}

export default MenuSection;
