import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
const DayAndTime = () => {
  const days = {
    sunday: false,
    saturday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false
  };
  const [TimeArr, SetTimeArray] = useState([]);
  // const [deleteIcon, setDltIcon] = useState(false);

  const DaysSelect = () => {
    SetTimeArray((prevState) => {
      prevState.push({
        startTime: '00:00',
        endTime: '24:00'
      });
      return [...prevState];
    });
    // setDltIcon(true);
    // console.log(TimeArr.length, "this is pushed");
  };

  return (
    <>
      <Row>
        <Col md="4">
          {Object.keys(days).map((day, key) => (
            <div className="d-flex align-items-center" style={{ height: 50 }} key={key}>
              <FormGroup check className="w-25 d-block w-100">
                <Label check>
                  <Input type="checkbox" name="checkbox" />{' '}
                  <span className="text-capitalize">{day}</span>
                </Label>
              </FormGroup>
            </div>
          ))}
        </Col>
        <Col md="8">
          <div className="ml-auto ">
            {TimeArr.map((d, i) => {
              return (
                <div className="d-flex" style={{ height: 50 }} key={i}>
                  <Row className="align-items-center">
                    <Col md="5">
                      <input
                        type="text"
                        className="d-block form-control border-top-0 border-right-0 border-left-0 bg-transparent"
                        placeholder="11:00"
                      />
                    </Col>
                    <Col md="5">
                      <input
                        type="text"
                        className="d-block form-control border-top-0 border-right-0 border-left-0 bg-transparent"
                        placeholder="11:00"
                      />
                    </Col>
                    <Col md="2" className="text-right">
                      <i
                        className="fa fa-trash"
                        onClick={() => {
                          SetTimeArray((prevState) => {
                            prevState = prevState.filter((_, k) => k != i);
                            return [...prevState];
                          });
                          // setDltIcon(false);
                        }}
                      ></i>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
          {TimeArr.length < 7 && (
            <div className="d-flex align-items-center ml-auto" style={{ width: 50 }}>
              <i className="fa fa-plus ml-auto" onClick={DaysSelect}></i>
            </div>
          )}
        </Col>
        <Col md="12">
          <div className="d-flex justify-content-center">
            <Button color="primary">Save</Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default DayAndTime;
