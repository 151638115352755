import { LAODING } from "store/Types/PermisionTypes";
import { TABLE_CHANGE_PERMISSION_SUCCESS } from "store/Types/PermisionTypes";
import { GET_TABLE_CHANGE_PERMISSION_SUCCEES } from "store/Types/PermisionTypes";

const initialData = {
  guestWall: {},
  isload: false,
  tableChange:false,
};
const permisionReducers = (state = initialData, action) => {
  switch (action.type) {
    case LAODING:
      return {
        ...state,
        isload: true
      };
    case 'GET_GUEST_PAGE_PERMISION':
      return {
        ...state,
        isload: false,
        guestWall: action.payload
      };
    case 'GUEST_PAGE_PERMISION':
      console.log(action.payload, 'the payloaf');
      return {
        ...state,
        isload: false,
        guestWall: action.payload
      };
      case GET_TABLE_CHANGE_PERMISSION_SUCCEES:
        return {
          ...state,
          isload: false,
          tableChange: action.payload
        };
      case TABLE_CHANGE_PERMISSION_SUCCESS:
        console.log(action.payload, 'the payloaf');
        return {
          ...state,
          isload: false,
          tableChange: action.payload
        };
    default:
      return state;
  }
};
export default permisionReducers;
