const initialData = {
  guestWall: [],
  newGuestWall: []
};
const guestReducers = (state = initialData, action) => {
  switch (action.type) {
    case 'GET_GUEST_WALLS':
      return {
        ...state,
        guestWall: action.payload
      };
    case 'CREATE_GUEST_WALL':
      return {
        ...state,
        newGuestWall: [...state.guestWall, action.payload]
      };
    case 'UPDATE_GUEST_WALL':
      return {
        ...state,
        newGuestWall: [...state.guestWall, action.payload]
      };
    case 'DELETE_GUEST_WALL':
      return {
        ...state,
        newGuestWall: state.guestWall.filter((elem) => elem.id !== action.id)
      };
    default:
      return state;
  }
};
export default guestReducers;
