/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { absoluteUrl } from "utils/backendUrl";
import axios from "axios";
import { useSelector } from "react-redux";
import { Switch,  MenuItem } from "@mui/material";
import toast from "react-hot-toast";
import { RefreshCcw, RefreshCw, Settings, Trash2, X , Plus , Save , Cancel } from "lucide-react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader , FormGroup , Form , Label , Input , Row  , Col, Alert } from 'reactstrap';
import { DataGrid, GridPagination, gridClasses } from "@mui/x-data-grid";
import MuiPagination from '@mui/material/Pagination';
import { Box, styled } from "@mui/system";
import { useForm , Controller , useFieldArray } from "react-hook-form"
import Select from 'react-select';

const tableStyle = {
  height: '55rem',
  width: '100%'
}

const modalStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '1rem',
  alignItems: 'center',
  width: '100%',
  padding: '0.5rem'
};

const paddingY = {
  paddingTop: '0.3rem',
  paddingBottom: '0.3rem'
}

const buttonStyle = {
  borderColor: 'transparent',
  borderRadius: '0.375rem',
  padding: '0.3rem 0.7rem',
  margin: '0.5rem',
  cursor: 'pointer',
  fontWeight: 'bold',
};

const cancelButtonStyle = {
  ...buttonStyle,
  backgroundColor: 'pink',
  color: '#fff',
};

const confirmButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#7bbaff',
  color: '#fff',
};

const floorPlanColumns = [
  { field: "_id", headerName: "ID", width: 150, hide: true },
  { field: "id", headerName: "id", width: 150, hide: true },
  {
    field: 'number', headerName: 'Table number', type: 'Number', width: 220,
    headerClassName: 'tablesHeader',
    headerAlign: 'left',
  },
  {
    field: 'description', headerName: 'Table name', width: 150,
    headerClassName: 'tablesHeader',
    headerAlign: 'left',
  },
  {
    field: 'defaultClientCount', headerName: 'Capacity', width: 220,
    headerClassName: 'tablesHeader',
    headerAlign: 'left',
  },
  {
    field: 'isNotificationEnabled', headerName: 'Notifications Enabled', width: 220,
    headerClassName: 'tablesHeader',
    headerAlign: 'left',
  },
  {
    field: 'active', headerName: 'Active', width: 220,
    headerClassName: 'tablesHeader',
    headerAlign: 'left',
  },
  {
    field: 'table_type', headerName: 'Type', width: 220,
    headerClassName: 'tablesHeader',
    headerAlign: 'left',
  },
];

const getTables = async (blId) => {
  try {
    const tables = await axios.get(absoluteUrl(`/floor-plans/getall/${blId}`))
    return tables.data?.data || []
  } catch (err) {
    console.log("ERROR GETTING TABLES", err)
  }
}

const Tables = () => {
  const { blId } = useSelector((state) => state.blId);
  const [tableIds, setTableIds] = useState([])
  const [isTablesSettingsOpen, setIsTablesSettingsOpen] = useState(false)
  const [isConfigNotificationOpen, setIsConfigNotificationOpen] = useState(false)

  const closeTablesSettingsModal = () => {
    setIsTablesSettingsOpen(false)
  }
  const openTablesSettingsModal = () => {
    setIsTablesSettingsOpen(true)
  }

  const { data: floorPlans, isLoading, refetch, isFetching } = useQuery({
    queryKey: ['tables', blId],
    queryFn: () => getTables(blId)
  });

  const syncTables = async () => {
    try {
      await axios.post(absoluteUrl(`/floor-plans/sync/${blId}`));
    } catch (err) {
      console.error("ERROR SYNCING TABLES", err)
      throw new Error("Error syncing tables");
    }
  }
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: syncTables,
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries(['tables', blId])
      toast.success('Sync done!');
    },
    onError: (error) => {
      toast.error(`Error syncing`);
    }
  });

  const selectedTables = useMemo(() => {
    if (tableIds.length > 0) return floorPlans.filter(floor => tableIds.includes(floor._id))
    return []
  }, [tableIds]);

  return (
    <>
      <div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '0.5rem', paddingBottom: '1rem' }}>
            {tableIds.length > 0 && <button onClick={() => openTablesSettingsModal()} style={{ borderColor: 'transparent', borderRadius: '0.375rem', color: 'white', paddingInline: '0.6rem', backgroundColor: '#1976d2', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.4rem' }}>
              <Settings size={20} />
              <span>Edit</span>
            </button>}
            <button onClick={() => mutation.mutate()} style={{ borderColor: 'transparent', borderRadius: '0.375rem', color: 'white', paddingInline: '0.6rem', backgroundColor: '#000000', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.4rem' }}>
              <RefreshCcw size={20} />
              <span>Sync</span>
            </button>

            <button onClick={() => setIsConfigNotificationOpen(true)} style={{ borderColor: 'transparent', borderRadius: '0.375rem', color: 'white', paddingInline: '0.6rem', backgroundColor: '#1976d2', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.4rem' }}>
              <Settings size={20} />
              <span>Notification Message</span>
            </button>

            
          </div>
          {(isLoading || isFetching) && <div>Tables loading...</div>}
          {!isLoading && floorPlans && floorPlans?.length === 0 ? (
            <div>No tables found in this restaurant</div>
          ) : (
            <div style={tableStyle}>
              {!isLoading &&
                <DataGrid
                  rows={floorPlans}
                  columns={floorPlanColumns}
                  hideFooterSelectedRowCount
                  sx={{
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                      outline: 'none',
                    },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                      {
                        outline: 'none',
                      },
                  }}
                  components={{
                    Pagination: CustomPagination
                  }}
                  checkboxSelection
                  onSelectionModelChange={(newSelectionModel) => {
                    const selectedIds = newSelectionModel.map(id => floorPlans.find((table) => table.id == id)?._id);
                    setTableIds(selectedIds);
                  }}
                />
              }
            </div>
          )}
        </div>
      </div>
      <TablesSettingsModal refetch={refetch} selectedTables={selectedTables} floorPlans={floorPlans} tableIds={tableIds} isTablesSettingsOpen={isTablesSettingsOpen} closeTablesSettingsModal={closeTablesSettingsModal} />
      <NotificationModal  isOpen={isConfigNotificationOpen} toggle={setIsConfigNotificationOpen} />

    </>
  );
};
export default Tables;

const  NotificationModal = ({isOpen , toggle})=> {
  console.log(isOpen)
  // const options = [
  //   { value: 'Standard-Service', label: 'Standard-Service' },
  //   { value: 'Pick-up', label: 'Pick-up' },
  //   { value: 'new', label: 'Add New Table Type' }
  // ];
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm()

  const [selectedTableType, setSelectedTableType] = useState(null);
  const [message1, setMessage1] = useState("")
  const [message2, setMessage2] = useState("")
  const [notifdata, setNotifdata] = useState();
  const [newTableType, setNewTableType] = useState('');
  const handleChange = selectedOption => {
    setSelectedTableType(selectedOption);
    if (!selectedOption) return null;

    const { value } = selectedOption;
    const selectedMessage = notifdata.find(item => item.tableType === value);

    if (selectedMessage) {
      setMessage1(selectedMessage.message);
      setMessage2(selectedMessage.message_two);
    } else{
      setMessage1('');
      setMessage2('');
    }
  };

  const getNotifMsg=async()=>{
    try {
      const response = await fetch(absoluteUrl(`/notification-message`));
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data);
      setNotifdata(data);

    } catch (error) {
      console.log(error.message);
    }
  }
  const options = notifdata?.map(item => ({ value: item.tableType, label: item.tableType })).concat({ value: 'new', label: 'Add New Table Type' });

  useEffect(() => {
    if (isOpen)   getNotifMsg()
  },[isOpen])

  const handleUpdate = () => {
    const myObj = {
      tableType: selectedTableType.value,
      message1: message1,
      message2: message2
    }
   axios.put(absoluteUrl(`/notification-message`), myObj)
    .then(res => {
      toggle(false)
    })

  };

  const handleAddNewTableType = () => {
    const myObj = {
      tableType: newTableType,
      globalMessage:message1,
      message: message1,
      message_two: message2
    }
   axios.post(absoluteUrl(`/notification-message`), myObj)
    .then(res => {
    
      toggle(false)
      setNewTableType('');
      setMessage1('');
      setMessage2('');
    })

  };
  return (
  <Modal centered isOpen={isOpen} toggle={toggle} size={'l'} >
  <ModalHeader style={{ display: 'flex', justifyContent: 'end' }}>
    
    <X 
    onClick={()=>toggle(false)}
     color={'red'} role={'button'} />
     
  </ModalHeader>
  <ModalBody >
  <Label>Table type</Label>
  <Select
        value={selectedTableType}
        onChange={handleChange}
        options={options}
        placeholder="Select a table type"
      />
      {selectedTableType && selectedTableType.value === 'new' && (
         <div style={{ marginTop: '1rem', alignSelf: 'flex' }}>
         <div style={{ display: 'flex', flexDirection: 'column'}}>
       
            <Row className="">
              <Col className= "mb-3">
                <Label>New Table Type</Label>
        
                <Input type="text" value={newTableType} 
                onChange={(e) => setNewTableType(e.target.value)} 
                placeholder="Enter new table type"
                />
              </Col>
            </Row>
            <Row className="">
              <Col className= "mb-3">
                <Label>First Message</Label>
        
                <Input type="textarea" value={message1} 
                onChange={(e) => setMessage1(e.target.value)} 
        
                />
              </Col>
            </Row>
            <Row className="">
              <Col className= "mb-3" >
              <Label>Second Message</Label>
                <Input type="textarea" value={message2} onChange={(e) => setMessage2(e.target.value)} />
              </Col>
            </Row>
            <div style={{ marginTop: '1rem', alignSelf: 'flex-end' }}>
            <Button onClick={handleAddNewTableType}>Add</Button>
            </div>
          </div>
          </div>
          
          )}
      {selectedTableType  && selectedTableType.value !== 'new'&& (
 <div style={{ marginTop: '1rem', alignSelf: 'flex' }}>
 <div style={{ display: 'flex', flexDirection: 'column'}}>
    <Row className="">
      <Col className= "mb-3">
        <Label>First Message</Label>

        <Input type="textarea" value={message1} 
        onChange={(e) => setMessage1(e.target.value)} 

        />
      </Col>
    </Row>
    <Row className="">
      <Col className= "mb-3" >
      <Label>Second Message</Label>
        <Input type="textarea" value={message2} onChange={(e) => setMessage2(e.target.value)} />
      </Col>
    </Row>
    <div style={{ marginTop: '1rem', alignSelf: 'flex-end' }}>
      <Button onClick={handleUpdate}>Update</Button>
    </div>
  </div>
  </div>
      )}
     
  </ModalBody>

  <Modal centered 
  // isOpen={isDeleteModalOpen}
  // toggle={CloseDeleteModal}
   >
    <ModalHeader
     //toggle={CloseDeleteModal}
     >
      Delete selected floor plans
    </ModalHeader>
    <ModalFooter>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <button
        // onClick={CloseDeleteModal} 
         style={cancelButtonStyle}>
          Cancel
        </button>
        <button 
       // onClick={handleDeleteFloorPlan}
         style={confirmButtonStyle}>
          Confirm
        </button>
      </div>
    </ModalFooter>
  </Modal>

</Modal >

)

}

function TablesSettingsModal({ tableIds, floorPlans, refetch, selectedTables, isTablesSettingsOpen, closeTablesSettingsModal }) {
  const [istableNotifActive, setIsTableNotifActive] = useState(false)
  const { blId } = useSelector((state) => state.blId);
  const [notifdata, setNotifdata] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const queryClient = useQueryClient()

  const handleActivateTableNotif = async () => {
    setIsTableNotifActive(!istableNotifActive)
  }

  const getFloorPlan = (tableId) => {
    return floorPlans.reduce((acc, floor) => {
      if (floor._id === tableId) {
        return floor;
      }
      return acc;
    }, null);
  }
  const [tableType, setTableType] = useState(null)
  const handleChangeTableType = (selectedOption) => {
    setTableType(selectedOption)
  }
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const CloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  }
  const OpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  }
  const mutation = useMutation({
    mutationFn: async () => {
      await axios.delete(absoluteUrl(`/floor-plans/selected-delete`), { data: { tableIds: tableIds } })
      CloseDeleteModal()
      closeTablesSettingsModal()
    },
    onSuccess: () => {
      queryClient.setQueryData(['tables', blId], prevData =>
        prevData.filter(floor => !tableIds.includes(floor._id))
      )
      toast.success('Floor plans deleted successfully!')
    }
  }
  )

  const handleDeleteFloorPlan = async () => {
    try {
      mutation.mutate()
    } catch (err) {
      console.log(err)
    }
  }
  const updateMutation = useMutation({
    mutationFn: async () => {
      await axios.patch(absoluteUrl(`/floor-plans/selected-update`), {
        tableIds: tableIds,
        isNotificationEnabled: istableNotifActive,
        table_type: tableType.value
      })
      closeTablesSettingsModal()
    },
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries(['tables', blId])
      toast.success('Updated successfully!');
    }
  }
  )
  const updateSelectedTables = async () => {
    try {
      if (tableType === null) {
        setErrorMessage('Please select a table type.');
        return;
      }
      updateMutation.mutate()
      setErrorMessage('');
      setTableType(null)
      setIsTableNotifActive(false)
    } catch (err) {
      console.log(err)
    }
  }
  const getNotifMsg=async()=>{
    try {
      const response = await fetch(absoluteUrl(`/notification-message`));
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log(data);
      setNotifdata(data);

    } catch (error) {
      console.log(error.message);
    }
  }
  const options = notifdata?.map(item => ({ value: item.tableType, label: item.tableType }));

  useEffect(() => {
    if (isTablesSettingsOpen)  {
       getNotifMsg()}
  },[isTablesSettingsOpen])
  return (
    <Modal centered isOpen={isTablesSettingsOpen} toggle={closeTablesSettingsModal} size={'lg'} >
      <ModalHeader style={{ display: 'flex', justifyContent: 'end' }}>
        <X onClick={closeTablesSettingsModal} color={'red'} role={'button'} />
      </ModalHeader>
      <ModalBody style={modalStyle}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
          {selectedTables.map((table) => (
            <div
              className={'text-lg'} style={{
                fontStyle: 'bold',
                backgroundColor: '#c7d2fe',
                borderRadius: '0.3rem',
                paddingInline: '0.5rem',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }} key={table._id}>
              <span>{table.description}</span>
              <span>Notification: {getFloorPlan(table._id)?.isNotificationEnabled ? 'ON' : 'OFF'}</span>
              <span>count: {table.defaultClientCount}</span>
            </div>
          ))}
        </div>
        <div style={
          paddingY,
          {
            paddingInline: '0.5rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem'
          }
        }
        >
          <div>
            <span>Notification</span>
            <Switch
              name="tablenotif"
              checked={istableNotifActive}
              onChange={handleActivateTableNotif}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Select
       ////   defaultValue={options[0]}
        value={tableType}
        onChange={handleChangeTableType}
        options={options}
        placeholder="Select a table type"
      />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={OpenDeleteModal}
          style={{
            backgroundColor: 'pink',
            borderColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Trash2 color='red' size={20} />
          <span className='text-red' >Delete</span>
        </Button>
        <Button style={{ backgroundColor: '#1976d2', borderColor: 'transparent' }} onClick={updateSelectedTables}>
          Update
        </Button>
      </ModalFooter>
      {errorMessage && <Alert color="danger" style={{ textAlign: 'center' }}>{errorMessage}</Alert >}
      <Modal centered isOpen={isDeleteModalOpen} toggle={CloseDeleteModal}>
        <ModalHeader toggle={CloseDeleteModal}>
          Delete selected floor plans
        </ModalHeader>
        <ModalFooter>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <button onClick={CloseDeleteModal} style={cancelButtonStyle}>
              Cancel
            </button>
            <button onClick={handleDeleteFloorPlan} style={confirmButtonStyle}>
              Confirm
            </button>
          </div>
        </ModalFooter>
      </Modal>

    </Modal >
  )
}

const CustomPagination = (props) => {
  return (
    <div className="custom-pagination">
      <GridPagination {...props} />
    </div>
  );
};
