export const LOADING_SETTING = 'LOADING_SETTING';

export const FETCH_GENERAL_SETTING = 'FETCH_GENERAL_SETTING';
export const UPDATE_GENERAL_SETTING = 'UPDARE_GENERAL_SETTING';

export const FETCH_APP_COLOR = 'FETCH_APP_COLOR';
export const APP_COLOR_UPDATE = 'APP_COLOR_UPDATE';
export const RIGHT_ICON_UPDATE = 'RIGHT_ICON_UPDATE';

export const UPDATE_TEXT_LOADING = 'LODING_UPADTE_TEXT';
export const SUCCESS_UPDATE_TEXT='SUCCESS_UPDATE_TEXT';
export const FAIL_UPDATE_TEXT ='FAIL_UPDATE_TEXT';

export const GET_TEXT_LOADING = 'GET_TEXT_LOADING';
export const GET_TEXT_SUCCESS='GET_TEXT_SUCCESS';
export const GET_TEXT_FAIL ='GET_TEXT_FAIL';

export const FETCH_ALL_SETTINGS = 'FETCH_ALL_SETTING';
export const LOADING_FAILED = 'LOADING_FAILED';
