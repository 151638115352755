import { RepositoryFactory } from 'repository/RepositoryFactory';
import * as settings from '../Types/settingsTypes';

let AppSetting = RepositoryFactory.get('appSettings');

export const generalSettingAction = (val) => async (dispatch) => {
  dispatch({ type: settings.LOADING_SETTING });
  let { data } = await AppSetting.generalSettings(val);
  dispatch({
    type: settings.UPDATE_GENERAL_SETTING,
    payload: data
  });
};

export const updateAppColors = (payload, id) => async (dispatch) => {
  try {
    dispatch({ type: settings.LOADING_SETTING });
    let response = await AppSetting.updateAppColor(payload, id);
    if (response?.data?.success) {
      dispatch({ type: settings.APP_COLOR_UPDATE, payload: response.data.data });
    }
  } catch (e) {
    console.log('Error updating App Colors', e);
    dispatch({ type: settings.LOADING_FAILED });
  }
};

export const updateRightIcons = (payload) => async (dispatch) => {
  try {
    dispatch({ type: settings.LOADING_SETTING });
    let response = await AppSetting.updateRightIcon(payload);
    if (response?.data?.success) {
      // console.log('icon update response, ', response)
      alert(response?.data?.message);
      let payload = { message: response?.data?.message, data: response?.data?.data };
      dispatch({ type: settings.RIGHT_ICON_UPDATE, payload: payload });
    }
  } catch (e) {
    console.log('Error updating icons', e);
    alert('Submission Failed');
    dispatch({ type: settings.LOADING_FAILED });
  }
};

export const getBusinessSettings = (payload) => async (dispatch) => {
  try {
    dispatch({ type: settings.LOADING_SETTING });
    let response = await AppSetting.getBusinessDetails(payload);
    if (response?.data?.success) {
      dispatch({ type: settings.FETCH_ALL_SETTINGS, payload: response.data.data });
    }
  } catch (e) {
    console.log('Error updating App Colors', e);
    dispatch({ type: settings.LOADING_FAILED });
  }
};


export const UpdateText = (payload) => async (dispatch) => {
  dispatch({type:settings.UPDATE_TEXT_LOADING});
  try{
    let response = await AppSetting.repoupdateText(payload);
    if(response.data.success){
      dispatch({ type: settings.SUCCESS_UPDATE_TEXT, payload: response?.data?.layoutTextAll });
    }
  }
    catch(error){
      console.log('Error updating App Colors', error);
    dispatch({ type: settings.FAIL_UPDATE_TEXT });
    }
  }


  export const getTextAndHeading = (payload) => async (dispatch) => {
    dispatch({type:settings.GET_TEXT_LOADING});
  
    try{
      let response = await AppSetting.getAppText(payload);
      if(response.data.success){
        dispatch({ type: settings.GET_TEXT_SUCCESS, payload: response.data.data });
      }
    }
      catch(error){
        console.log('Error updating App Colors', error);
      dispatch({ type: settings.GET_TEXT_FAIL });
      }
    }
