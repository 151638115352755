import { FETCH_MENU_FAILURE } from 'store/Types/MenuTypes';
import { SET_MENU_POSITION_SUCCESS } from 'store/Types/MenuTypes';
import { FETCH_KIOSK_MENU_REQUEST } from 'store/Types/MenuTypes';
import { FETCH_TAKEAWAY_MENU_REQUEST } from 'store/Types/MenuTypes';
import { SET_PRIORITY_REQUEST } from 'store/Types/MenuTypes';
import { DELETE_MENU_REQUEST } from 'store/Types/MenuTypes';
import { DELETE_MENU_FAILURE } from 'store/Types/MenuTypes';
import { CREATE_MENU_SUCCESS } from 'store/Types/MenuTypes';
import { FETCH_SINGLE_MENU_ITEMS_REQUEST } from 'store/Types/MenuTypes';
import { FETCH_ALLE_ARTIKELENA_FAILURE } from 'store/Types/MenuTypes';
import { ADD_SINGLE_MENU_REQUEST } from 'store/Types/MenuTypes';
import { UPDATE_MENU_POSITION_REQUEST } from 'store/Types/MenuTypes';
import { UPDATE_MENU_POSITION_FAILURE } from 'store/Types/MenuTypes';
import { UPDATE_GROUP_POSITION_REQUEST } from 'store/Types/MenuTypes';
import { UPDATE_GROUP_POSITION_FAILURE } from 'store/Types/MenuTypes';
import { FETCH_GROUP_SUCCESS } from 'store/Types/MenuTypes';
import { FETCH_GROUP_FAILURE } from 'store/Types/MenuTypes';
import { FETCH_GROUP_REQUEST } from 'store/Types/MenuTypes';
import { UPDATE_GROUP_POSITION_SUCCESS } from 'store/Types/MenuTypes';
import { UPDATE_MENU_POSITION_SUCCESS } from 'store/Types/MenuTypes';
import { ADD_SINGLE_MENU_FAILURE } from 'store/Types/MenuTypes';
import { ADD_SINGLE_MENU_SUCCESS } from 'store/Types/MenuTypes';
import { FETCH_ALLE_ARTIKELENA_REQUEST } from 'store/Types/MenuTypes';
import { CREATE_MENU_FAILURE } from 'store/Types/MenuTypes';
import { CREATE_MENU_REQUEST } from 'store/Types/MenuTypes';
import { DELETE_MENU_SUCCESS } from 'store/Types/MenuTypes';
import { SET_PRIORITY_FAILURE } from 'store/Types/MenuTypes';
import { FETCH_TAKEAWAY_MENU_FAILURE } from 'store/Types/MenuTypes';
import { FETCH_KIOSK_MENU_FAILURE } from 'store/Types/MenuTypes';
import { SET_MENU_POSITION_FAILURE } from 'store/Types/MenuTypes';
import { SET_MENU_POSITION_REQUEST } from 'store/Types/MenuTypes';
import { FETCH_MENU_REQUEST } from 'store/Types/MenuTypes';
import {
  SET_PRIORITY_SUCCESS,
  FETCH_KIOSK_MENU_SUCCESS,
  FETCH_TAKEAWAY_MENU_SUCCESS,
  FETCH_SINGLE_MENU_ITEMS_FAILURE,
  FETCH_SINGLE_MENU_ITEMS_SUCCESS,
  FETCH_MENU_SUCCESS,
  FETCH_ALLE_ARTIKELENA_SUCCESS
} from 'store/Types/MenuTypes';
import { GET_GROUP } from 'store/types';

let initState = {
  loading: false,
  menuAddLoader: false,
  getMenuLoader: false,
  getAlleArtikelenLoader: false,
  menus: [],
  group:[],
  loadingGroup: false,
  menuItems: [],
  takeAwaymenus: [],
  kioskMenus: [],
  alleArtikelen: [],
  addPriority: []
};
export default function menuReducer(state = initState, action) {
  switch (action.type) {
    case SET_MENU_POSITION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_MENU_POSITION_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SET_MENU_POSITION_FAILURE:
      return {
        ...state,
        loading: false
      };
      case UPDATE_MENU_POSITION_REQUEST:
        return {
          ...state,
          getMenuLoader: true
      };
    case UPDATE_MENU_POSITION_SUCCESS:
        return {
          ...state,
          menus: action.payload,
          getMenuLoader: false
      };
    case UPDATE_MENU_POSITION_FAILURE:
        return {
          ...state,
          getMenuLoader: false,
          menus: []
      };

    case FETCH_MENU_REQUEST:
      return {
        ...state,
        getMenuLoader: true
      };
    case FETCH_MENU_SUCCESS:
      return {
        ...state,
        menus: action.payload,
        getMenuLoader: false
      };
    case FETCH_MENU_FAILURE:
      return {
        ...state,
        getMenuLoader: false,
        menus: []
      };
      case GET_GROUP: {

        return {
          ...state,
          loadingGroup: true,
          group: action.payload
        };
    };
    case UPDATE_GROUP_POSITION_REQUEST:
      return {
        ...state,
        getMenuLoader: true
    };
  case UPDATE_GROUP_POSITION_SUCCESS:
      return {
        ...state,
        group: action.payload,
        loadingGroup: false
    };
  case UPDATE_GROUP_POSITION_FAILURE:
    return {
      ...state,
      loadingGroup: false,
      group: []
  };
    case FETCH_GROUP_REQUEST:
        return {
          ...state,
          loadingGroup: true
        };
    case FETCH_GROUP_SUCCESS:
        return {
          ...state,
          group: action.payload,
          loadingGroup: false
        };
    case FETCH_GROUP_FAILURE:  
      return {
        ...state,
        loadingGroup: false,
        group: []
      };
    case ADD_SINGLE_MENU_REQUEST:
      return {
        ...state,
        menuAddLoader: true
      };
    case ADD_SINGLE_MENU_SUCCESS:
      return {
        ...state,
        menus: [...state.menus, action.payload],
        menuAddLoader: false
      };
    case ADD_SINGLE_MENU_FAILURE:
      return {
        ...state,
        menus: [...state.menus],
        menuAddLoader: false
      };
    case FETCH_ALLE_ARTIKELENA_REQUEST:
      return {
        ...state,
        getAlleArtikelenLoader: true
      };
    case FETCH_ALLE_ARTIKELENA_SUCCESS:
      return {
        ...state,
        alleArtikelen: action.payload,
        getAlleArtikelenLoader: false
      };
    case FETCH_ALLE_ARTIKELENA_FAILURE:
      return {
        ...state,
        alleArtikelen: [],
        getAlleArtikelenLoader: false
      };
    case FETCH_TAKEAWAY_MENU_REQUEST:
      return {
        ...state,
        getMenuLoader: true
      };
    case FETCH_TAKEAWAY_MENU_SUCCESS:
      return {
        ...state,
        takeAwaymenus: action.payload,
        getMenuLoader: false
      };
    case FETCH_TAKEAWAY_MENU_FAILURE:
      return {
        ...state,
        takeAwaymenus: [],
        getMenuLoader: false
      };
    case FETCH_KIOSK_MENU_REQUEST:
      return {
        ...state,
        getMenuLoader: true
      };
    case FETCH_KIOSK_MENU_SUCCESS:
      return {
        ...state,
        kioskMenus: action.payload,
        getMenuLoader: false
      };
    case FETCH_KIOSK_MENU_FAILURE:
      return {
        ...state,
        kioskMenus: [],
        getMenuLoader: false
      };
    case FETCH_SINGLE_MENU_ITEMS_REQUEST:
      return {
        ...state,
        getMenuLoader: true
      };
    case FETCH_SINGLE_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        menuItems: action.payload,
        getMenuLoader: false
      };
    case FETCH_SINGLE_MENU_ITEMS_FAILURE:
      return {
        ...state,
        menuItems: [],
        getMenuLoader: false
      };
    case SET_PRIORITY_REQUEST:
      return {
        ...state,
      };
    case SET_PRIORITY_SUCCESS:
      return {
        ...state,
        addPriority: action.payload,
      };
    case SET_PRIORITY_FAILURE:
      return {
        ...state,
        addPriority: [],
      };
    case DELETE_MENU_REQUEST:
      return {
        ...state,
        menuAddLoader: true
      };
    case DELETE_MENU_SUCCESS:
      return {
        ...state,
        menuAddLoader: false
      };
    case DELETE_MENU_FAILURE:
      return {
        ...state,
        menuAddLoader: false
      };
    case CREATE_MENU_REQUEST:
      return {
        ...state,
        menuAddLoader: true
      };
    case CREATE_MENU_SUCCESS:
      return {
        ...state,
        menuAddLoader: false
      };
    case CREATE_MENU_FAILURE:
      return {
        ...state,
        menuAddLoader: false
      };
    default:
      return { ...state };
  }
}
