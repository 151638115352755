import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Col,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Input,
  Label,
  Badge,
  Spinner
} from 'reactstrap';
import QRCode from 'react-qr-code';

const QrCode = (props) => {
  let QrData = props.data;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const liveURL = process.env.REACT_APP_LIVE_URL;
  const { getFloorPlansLoader } = useSelector((state) => state.floor_plans);
  const { blId } = useSelector((state) => state.blId);

  const [editIdx, setEditIdx] = useState('');
  const [editTableno, setEditTableno] = useState('');
  const [editCode, setEditCode] = useState('');
  // const [editItem, setEditItem] = useState(null);

  const toggle = () => setShow(!show);

  const setUpdate = (item, idx) => {
    setEditIdx(idx);
    // setEditItem(item.tableno);
    setEditTableno(item.tableno);
    setEditCode(item.code);
    toggle();
  };

  const updateData = () => {
    setLoading(true);
    console.log({ editIdx });
    props.setData((prevProps) => {
      prevProps[editIdx].tableno = editTableno;
      prevProps[editIdx].code = editCode;
      return [...prevProps];
    });
    setLoading(false);
    toggle();
  };

  const deleteItem = (item) => {
    var filtered = QrData.filter(
      (qrdata) => qrdata.tableno !== item.tableno && qrdata.code !== item.qrcode
    );

    props.setData(filtered);
  };

  // let testURL = 'https://vapiano-main-test.web.app';
  // let localURL = 'http://localhost:3000';

  const QrLink = (number, url) => {
    if (Object.prototype.hasOwnProperty.call(props, 'takeAway')) {
      return `${url}/?buid=${blId}&table_no=${number}&takeaway=${props?.takeAway.toString()}`;
    } else {
      return `${url}/?buid=${blId}&table_no=${number}`;
    }
  };

  return (
    <>
      <Modal isOpen={show}>
        <ModalHeader toggle={toggle}>Edit QrCode</ModalHeader>
        <ModalBody>
          <Label>Table No</Label>
          <Input
            placeholder="Table No"
            type="text"
            value={editTableno}
            onChange={(e) => setEditTableno(e.target.value)}
          />
          <Label>Code</Label>
          <Input
            placeholder="Code"
            type="text"
            value={editCode}
            onChange={(e) => setEditCode(e.target.value)}
          />
          {loading ? (
            <Spinner />
          ) : (
            <Button
              onClick={updateData}
              className="mt-5 float-right"
              type="submit"
              disabled={!editTableno || !editCode}
            >
              Update
            </Button>
          )}
        </ModalBody>
      </Modal>
      {getFloorPlansLoader ? (
        <Spinner className="d-flex mx-auto mt-5" color="primary" size="lg" />
      ) : (
        <>
          {QrData.length > 0 &&
            QrData.map((prop, index) => {
              return (
                <Col xs="12" sm="12" md="4" lg="4" className="p-2" key={index}>
                  <h3 className="text-dark">{prop?.title}</h3>
                  <div className="card shadow p-3 mb-5 bg-white rounded h-100 ">
                    <div className="d-flex">
                      <p>
                        <b className="font-weight-bold">Table No -</b> {prop.number}
                      </p>
                      <h5 className="ml-auto">
                        <Badge color="success">{prop.name}</Badge>
                      </h5>
                    </div>
                    <div className="card-header">
                      {/* <p>
                        <b className="font-weight-bold">Table No -</b>{" "}
                        {prop.number}
                      </p> */}
                      <p>{prop.description}</p>

                      <div className="text-center">
                        <a target="_blank" href={QrLink(prop.number, liveURL)} rel="noreferrer">
                          Visit Url
                        </a>
                      </div>
                    </div>
                    <div
                      className="d-flex
											justify-content-center mt-4"
                    >
                      <QRCode value={QrLink(prop.number, liveURL)} />
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <i
                        className="fas fa-edit fa-2x cursor mr-3 "
                        onClick={() => setUpdate(prop, index)}
                      ></i>
                      <i
                        className="fa fa-trash fa-2x cursor text-red"
                        aria-hidden="true"
                        onClick={() => deleteItem(prop)}
                      ></i>
                    </div>
                  </div>
                </Col>
              );
            })}
        </>
      )}
    </>
  );
};

export default QrCode;
