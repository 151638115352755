/*!

=========================================================
* Vapiano Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Elktech (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Elktech

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'assets/plugins/nucleo/css/nucleo.css';
import 'assets/scss/argon-dashboard-react.scss';
import 'assets/scss/index.scss';
import 'assets/scss/argon-dashboard-react.scss';
import 'assets/scss/ComponentsCss/summary.scss';

import 'assets/scss/index.scss';

import store from './store';
import App from './App';
import axios from 'axios';
axios.defaults.headers.common['blId'] = sessionStorage.getItem('blId');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
