import { RepositoryFactory } from 'repository/RepositoryFactory';
import { TABLE_CHANGE_PERMISSION_SUCCESS } from 'store/Types/PermisionTypes';
import { GET_TABLE_CHANGE_PERMISSION_SUCCEES } from 'store/Types/PermisionTypes';
import { GET_GUEST_PAGE_PERMISION } from 'store/Types/PermisionTypes';
import { GUEST_PAGE_PERMISION, LAODING, ERROR_PERMISSION } from 'store/Types/PermisionTypes';

var permissionsRepo = RepositoryFactory.get('permissions');

export const guestWallStatusAction = () => async (dispatch) => {
  try {
    dispatch({ type: LAODING });
    const res = await permissionsRepo.guestWallSwitchStatus();
    dispatch({
      type: GET_GUEST_PAGE_PERMISION,
      payload: res?.data?.data.guest_book
    });
  } catch (error) {
    dispatch({
      type: ERROR_PERMISSION,
      payload: error
    });
  }
};
export const guestWallAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LAODING });
    const res = await permissionsRepo.guestWallSwitch(payload);
    dispatch({
      type: GUEST_PAGE_PERMISION,
      payload: res?.data?.data.guest_book
    });
  } catch (error) {
    dispatch({
      type: ERROR_PERMISSION,
      payload: error
    });
  }
};
export const tableChangeStatusAction = () => async (dispatch) => {
  try {
    dispatch({ type: LAODING });
    const res = await permissionsRepo.tableChangeStatusAction();
    dispatch({
      type: GET_TABLE_CHANGE_PERMISSION_SUCCEES,
      payload: res?.data?.data?.table_change
    });
  } catch (error) {
    dispatch({
      type: ERROR_PERMISSION,
      payload: error
    });
  }
};
export const enableDisableTableChangeStatus = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LAODING });
    const res = await permissionsRepo.enableDisableTableChangeStatus(payload);
    console.log(res)
    dispatch({
      type: TABLE_CHANGE_PERMISSION_SUCCESS,
      payload: res?.data?.data?.table_change
    });
  } catch (error) {
    dispatch({
      type: ERROR_PERMISSION,
      payload: error
    });
  }
};
