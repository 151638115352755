import React from 'react';
import { Col } from 'reactstrap';
import { updateGuestWall, deleteGuestWall } from 'store/actions/guestActions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import ErrorModal from 'components/Basic Modal/ErrorModal';

const GuestCard = (props) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  const dispatch = useDispatch();
  const onClickHandler = () => {
    // console.log("clicked");
    if (props.status !== 'approved') {
      // console.log("disptached update")
      dispatch(updateGuestWall(props._id, props.blId)).then((resp) => {
        if (resp) {
          setShowErrorMsg('Guest Wall Updated succesfully');
          setShowErrorModal(true);
        }
      });
    }
  };

  const RemoveHandler = () => {
    dispatch(deleteGuestWall(props._id, props.blId)).then((resp) => {
      if (resp) {
        setShowErrorMsg('Guest Wall Deleted succesfully');
        setShowErrorModal(true);
      }
    });
  };
  return (
    <>
      <Col xs="12" sm="12" md="4" lg="3" className="mt-2">
        <div className=" shadow bg-white rounded card h-100 ">
          <div className="card-header d-flex flex-row justify-content-between">
            <button
              onClick={onClickHandler}
              className={
                'btn custom-button btn-md' +
                (props.status === 'approved' ? ' disabled' : ' btn-success')
              }
            >
              Approve
            </button>
            <button onClick={RemoveHandler} className="btn btn-danger custom-button btn-sm">
              Remove
            </button>
          </div>
          <div className="">
            <img src={props.signature} alt="sign" className="w-100" />
          </div>
        </div>
      </Col>
      <ErrorModal
        display={showErrorModal}
        size="lg"
        center="true"
        handleClose={() => {
          setShowErrorModal(false);
        }}
        footer={true}
        content={
          <>
            <h1 className="text-center">{showErrorMsg}</h1>
          </>
        }
      />
    </>
  );
};

export default GuestCard;
