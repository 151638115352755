import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuSection from 'components/MenuSection';
import { Row, Col, Button } from 'reactstrap';
import PageTitle from 'components/PageTitle';
import {
  getAllAlleArtikelen,
  getAllKioskMenu,
  getAllMenus,
  getAllTakeAwayMenus
} from '../../../store/actions/menuActions';

const Menus = () => {
  const dispatch = useDispatch();
  const [activeMenu, setMenu] = useState('In Store');

  const { blId, bussines_menu } = useSelector((state) => state.business);
  // const [instore, setInStore] = useState([
  // const instore = [
  //   {
  //     name: 'Insalata Mista',
  //     price: 2.95
  //   },

  //   {
  //     name: 'Insalata Reef N Beef',
  //     price: 15.95
  //   },
  //   {
  //     name: 'Insalata Burrata Con Verdure',
  //     price: 13.95
  //   }
  // ];
  // // const [takeaway, setTakeway] = useState([
  // const takeaway = [
  //   {
  //     name: 'San bitter orange',
  //     price: 4.9
  //   },
  //   {
  //     name: 'London Leaves',
  //     price: 4.9
  //   }
  // ];
  // const combined = () => {
  //   let arrs = ArticleData.map((item) => {
  //     return item.items;
  //   }).flat();
  //   if (activeMenu == 'In Store') {
  //     return arrs.filter((el) => {
  //       return !instore.find((item) => item.name.includes(el.name));
  //     });
  //   } else if (activeMenu == 'Takeaway & Delivery') {
  //     return arrs.filter((el) => {
  //       return !takeaway.find((item) => item.name.includes(el.name));
  //     });
  //   }
  // };
  // let ls = localStorage.getItem('blId');
  useEffect(() => {
    if (blId) {
      dispatch(getAllMenus(blId));
    }
  }, [blId]);
  useEffect(() => {
    if (blId && bussines_menu) {
      dispatch(getAllAlleArtikelen(blId, bussines_menu));
    }
  }, [blId, bussines_menu]);
  return (
    <>
      <Row className="p-3">
        <Col lg="6" md="6" sm="12" xs="12">
          <div className="card p-2 border border-light rounded">
            <PageTitle title="Menus" icon="/img/sidebar/burger.png" />
          </div>
        </Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <div className="mt-3">
            <Button
              outline={activeMenu != 'In Store' ? true : false}
              color="primary"
              onClick={() => {
                setMenu('In Store');
                dispatch(getAllMenus(blId));
              }}
            >
              In Store
            </Button>
            <Button
              outline={activeMenu != 'Takeaway & Delivery' ? true : false}
              color="primary"
              onClick={() => {
                setMenu('Takeaway & Delivery');
                dispatch(getAllTakeAwayMenus(blId));
              }}
            >
              Takeaway & Delivery{' '}
            </Button>
            <Button
              outline={activeMenu != 'Kiosk Menu' ? true : false}
              color="primary"
              onClick={() => {
                setMenu('Kiosk-Menu');
                dispatch(getAllKioskMenu(blId));
              }}
            >
              Kiosk Menu
            </Button>
          </div>
        </Col>
        <Col lg="12" md="12" sm="12" xs="12">
          <div className="mt-3">
            <Row>
              <Col md="6">
                <MenuSection
                  title={activeMenu}
                  menu_tag={activeMenu}
                  // combined={
                  // 	activeMenu == 'In Store'
                  // 		? instore
                  // 		: takeaway
                  // }
                  isSync={true}
                  menus={activeMenu}
                />
              </Col>
              <Col md="6">
                <MenuSection
                  title="Alle Artikelen"
                  menu_tag={activeMenu}
                  menus={activeMenu}
                  // returnItem={e => {
                  // 	if (activeMenu == 'In Store') {
                  // 		setInStore([...instore, e]);
                  // 	} else {
                  // 		setTakeway([...takeaway, e]);
                  // 	}
                  // 	console.log(e);
                  // }}
                  // combined={combined()}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Menus;
