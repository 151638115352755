import { RepositoryFactory } from 'repository/RepositoryFactory';
let PromotionsRepository = RepositoryFactory.get('promotion');

export const getPopupLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'SET_POPUP_LOADING',
    payload: val
  });
};

export const getNewsLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'SET_NEWS_LOADING',
    payload: val
  });
};
export const getHeaderLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'SET_HEADER_LOADING',
    payload: val
  });
};

export const getPopUp = (id) => async (dispatch) => {
  // dispatch(getPopUp())
  try {
    // console.log("in getPopUp 1")
    let { data } = await PromotionsRepository.getPopUp(id);
    if (data.success) {
      // console.log("in getPopUp 2")
      dispatch({
        type: 'GET_POP_UP',
        payload: data.data
      });
    }
  } catch (err) {
    console.log('error', err.message);
  }
};

export const addImagePopUp = (payload) => async (dispatch) => {
  try {
    dispatch(getPopupLoader(true));
    let { data } = await PromotionsRepository.addPopUp(payload);
    if (data.success) {
      dispatch(getPopUp(payload.blId));
      alert('Pop Up Added succesfully');
      dispatch(getPopupLoader(false));
    }
  } catch (err) {
    dispatch(getPopupLoader(false));
    console.log('error', err.message);
  }
};

export const addPopUp = (payload) => async (dispatch) => {
  try {
    dispatch(getPopupLoader(true));
    let { data } = await PromotionsRepository.addPopUp(payload);
    if (data.success) {
      dispatch(getPopUp(payload.blId));
      // alert("Pop Up Added succesfully")
      dispatch(getPopupLoader(false));
    }
  } catch (err) {
    dispatch(getPopupLoader(false));
    console.log('error', err.message);
  }
};

export const deletePopUp = (_id) => async () => {
  try {
    let { data } = await PromotionsRepository.deletePopUp(_id);
    if (data.success) {
      alert('Pop Up Deleted succesfully');
      // dispatch(getPopUp());
    }
  } catch (err) {
    console.log('error', err.message);
  }
};

export const updatePopUpURL = (payload) => async (dispatch) => {
  try {
    dispatch(getPopupLoader(true));
    let { data } = await PromotionsRepository.updatePopUpURL(payload);
    if (data.success) {
      alert('PopUp URL updated succesfully');
      // dispatch(getPopUp());
      dispatch(getPopupLoader(false));
    }
  } catch (err) {
    dispatch(getPopupLoader(false));
    console.log('error', err.message);
  }
};
export const getHeader = (id) => async (dispatch) => {
  // dispatch(getHeader())
  try {
    dispatch(getHeaderLoader(true));
    let { data } = await PromotionsRepository.getHeader(id);
    console.log('data', data);
    if (data.success) {
      dispatch({
        type: 'GET_HEADER',
        payload: data.data
      });
      dispatch(getHeaderLoader(false));
    }
  } catch (err) {
    dispatch(getHeaderLoader(false));
    console.log('error', err.message);
  }
};
export const addHeader = (payload) => async (dispatch) => {
  try {
    dispatch(getHeaderLoader(true));
    let { data } = await PromotionsRepository.addHeader(payload);
    if (data.success) {
      dispatch(getHeader(payload.blId));
      alert('Header Added succesfully');
      dispatch(getHeaderLoader(false));
    }
  } catch (err) {
    dispatch(getHeaderLoader(false));
    console.log('error', err.message);
  }
};
export const deleteHeader = (_id, blId) => async (dispatch) => {
  try {
    dispatch(getHeaderLoader(true));
    let { data } = await PromotionsRepository.deleteHeader(_id);
    if (data.success) {
      alert('Header Deleted succesfully');
      // dispatch(getPopUp());
      dispatch(getHeader(blId));
      dispatch(getHeaderLoader(false));
    }
  } catch (err) {
    dispatch(getHeaderLoader(false));
    console.log('error', err.message);
  }
};
export const updateHeaderURL = (payload) => async (dispatch) => {
  try {
    dispatch(getHeaderLoader(true));
    let { data } = await PromotionsRepository.updateHeaderURL(payload);
    if (data.success) {
      alert('Header URL updated succesfully');
      dispatch(getHeaderLoader(false));
    }
  } catch (err) {
    dispatch(getHeaderLoader(false));
    console.log('error', err.message);
  }
};
export const getNews = (id) => async (dispatch) => {
  try {
    dispatch(getNewsLoader(true));
    let { data } = await PromotionsRepository.getNews(id);
    if (data.success) {
      dispatch({
        type: 'GET_NEWS',
        payload: data.data
      });
      dispatch(getNewsLoader(false));
    }
  } catch (err) {
    dispatch(getNewsLoader(false));
    console.log('error', err.message);
  }
};

export const addNews = (payload) => async (dispatch) => {
  try {
    dispatch(getNewsLoader(true));
    let { data } = await PromotionsRepository.addNews(payload);
    if (data.success) {
      dispatch(getNews(payload.blId));
      alert('News Added succesfully');
      dispatch(getNews());
      dispatch(getNewsLoader(false));
    }
  } catch (err) {
    dispatch(getNewsLoader(false));
    console.log('error', err.message);
  }
};
export const deleteNews = (_id, blId) => async (dispatch) => {
  try {
    dispatch(getNewsLoader(true));
    let { data } = await PromotionsRepository.deleteNews(_id);
    if (data.success) {
      alert('News Deleted succesfully');
      dispatch(getNews(blId));
      // dispatch(getPopUp());
      dispatch(getNewsLoader(false));
    }
  } catch (err) {
    dispatch(getNewsLoader(false));
    console.log('error', err.message);
  }
};
export const updateNewsURL = (payload) => async (dispatch) => {
  try {
    dispatch(getNewsLoader(true));
    let { data } = await PromotionsRepository.updateNewsURL(payload);
    if (data.success) {
      dispatch(getNewsLoader(false));
      alert('News URL updated succesfully');
    }
  } catch (err) {
    dispatch(getNewsLoader(false));
    console.log('error', err.message);
  }
};
