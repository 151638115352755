const initialData = {
  logo: {},
  isload: false
};
const storeSettingReducers = (state = initialData, action) => {
  switch (action.type) {
    case 'GET_STORE_LOGO':
      return {
        ...state,
        logo: action.payload
      };
    case 'ADD_STORE_LOGO':
      return {
        ...state,
        logo: action.payload
      };
    case 'LOADER_CHANGE':
      return {
        ...state,
        isload: action.payload
      };
    default:
      return state;
  }
};
export default storeSettingReducers;
