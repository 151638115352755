import { Button, Col, Row } from "reactstrap";

const AppIconUploadCom =({textLabel,resolution,currentSettings,iconSave,iconUploads,getImageInput,iconLoading,handleIconUploads,name,id})=>{
    return(
        <Col xs="12">
            <div className="shadow bg-white rounded mt-5">
              <div className="wrapper p-3 ">
                <div className="title d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <img src="/suitcase.png" alt="clock" height="20px" className="mr-3" />

                    <span>
                      <h4 className="mb-0 text-black">
                        {textLabel}
                        {currentSettings ? (
                          <img
                            alt="current_icon"
                            width={60}
                            height={50}
                            src={currentSettings}
                          />
                        ) : null}
                      </h4>
                    </span>
                  </div>
                  <div className="d-flex ml-auto">
                    {iconSave? (
                      <span
                        className="spinner-border spinner-border-lg text-info text-center"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <Button
                        disabled={iconUploads !== null || undefined ? false : true}
                        color="primary"
                        className="text-uppercase px-4"
                        name={name}
                        onClick={(e) => handleIconUploads(e)}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </div>
                <Row className="mt-3">
                  <Col md="12" className="mt-4 mt-md-0">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center w-100">
                        <div className="d-flex flex-column flex-shrink-0">
                          <small className="font-weight-semi-bold flex-shrink-0">Background</small>
                          <small className="font-weight-semi-bold flex-shrink-0">Max {resolution}</small>
                        </div>
                        <div className="w-100 border ml-4 p-2">
                          <span className="text-muted d-flex align-items-center pl-4">
                            {iconLoading? (
                              <span
                                className="spinner-border spinner-border-lg text-info text-center"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              <label className="custom-file-upload w-100" htmlFor={name}>
                                <input
                                  disabled={iconLoading}
                                  type="file"
                                  className="w-100"
                                  alt="image_upload"
                                  id={id}
                                  name={name}
                                  placeholder="bkiljkl"
                                  onChange={(e) => getImageInput(e)}
                                  hidden={true}
                                />
                                {iconUploads=== null || undefined
                                  ? 'Update Image'
                                  : `Image Selected: ${
                                       iconUploads !== null || undefined
                                        ? iconUploads.name
                                        : 'Uknown'
                                    }`}
                                <i className="fa fa-plus ml-3 "></i>
                              </label>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
    )
}

export default AppIconUploadCom;