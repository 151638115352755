import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { absoluteUrl } from 'utils/backendUrl';
import { successToast,errorToast } from 'components/Toasts/toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DialogSlide from 'components/Dialogs/Dialog';

export default function ParkingStationTable({ allParkingStations }) {
    const queryClient = useQueryClient();
    const [stationId, setStationId] = useState();
    // const [orderId, setOrderId] = useState();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isBumpDialogOpen, setIsBumpDialogOpen] = useState(false);

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true);
    };
    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const openBumpDialog = () => {
        setIsBumpDialogOpen(true);
    };
    const closeBumpDialog = () => {
        setIsBumpDialogOpen(false);
    };

    const deleteParkingStation = useMutation({
        mutationFn: async (stationId) => {
            const parkingStations = await axios.delete(
                absoluteUrl(`/parkingstations/deleteParkingStation/${stationId}`)
            );

            if (!parkingStations) {
                errorToast('❌ Error happened while deleting parking station');
                throw new Error('Error occured while deleting parking station !');
            }

            successToast('Parking stations have been deleted successfully!');

            return parkingStations;
        },
        onSuccess: () => {
            // this refetches again the groups from server
            // just as a backup plan
            queryClient.invalidateQueries(['allParkingStations']);
        }
    });

    const reset = useMutation({
        mutationFn: async (stationId) => {
            console.log('station name', stationId);
            const newGroups = await axios.post(
                absoluteUrl(`/parkingstations/bumpOrderFromParkingStation/${stationId}`)
            );

            if (!newGroups) {
                // handleOpenSnackBar("❌ Error happened while reordering groups")
                errorToast('❌ Error happened while bumping order from parking station');
                throw new Error('Error occured while bumping order from parking station!');
            }

            successToast('🎉 Order bumped successfully from parking station');

            return newGroups;
        },
        onSuccess: () => {
            // this refetches again the groups from server
            // just as a backup plan
            queryClient.invalidateQueries(['allParkingStations']);
        }
    });

    async function handleStationDelete(stationId) {
        try {
            deleteParkingStation.mutate(stationId);
        } catch (error) {
            console.log('ERROR_RESETING_GROUPS_ORDER', error);
        }
    }

    async function BumpParkingStation(stationId) {
        try {
            reset.mutate(stationId);
        } catch (error) {
            console.log('ERROR_UPDATING_PARKING_STATION', error);
        }
    }

    return (
        <div className="mt-3 card">
            <Table striped responsive>
                <thead>
                <tr>
                    <th>#</th>
                    <th className="text-center">Slot Name</th>
                    <th className="text-center">Availability</th>
                    <th className="text-center">Order</th>
                    <th className="text-center">Actions</th>
                </tr>
                </thead>
                <tbody>
                {allParkingStations?.map((data, ind) => {
                    /*const status = data.busy ? true yes : no */
                    // console.log(data)
                    return (
                        <tr key={ind}>
                            <th scope="row">{ind + 1}</th>
                            <td className="text-center">{data.stationName} </td>
                            <td className="text-center" style={{ color: data.busy === true ? 'red' : 'green' }}>
                                {data.busy === true ? 'Not available' : 'Available'}
                            </td>
                            <td className="text-center">
                                {data.order.orderName ? data.order.orderName : 'Empty'}{' '}
                            </td>
                            <td className="text-center">
                                <div style={{ justifyContent: 'center', display: 'flex', gap: '4px' }}>
                                    <Button
                                        style={{ backgroundColor: 'black', color: 'white', borderRadius: '4px' }}
                                        onClick={() => {
                                            openBumpDialog();
                                            setStationId(data._id);
                                        }}
                                    >
                                        Bump
                                    </Button>
                                    <Button
                                        style={{ justifyContent: 'center', display: 'flex' }}
                                        onClick={() => {
                                            setStationId(data._id);
                                            openDeleteDialog();
                                        }}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>

            <DialogSlide
                open={isDeleteDialogOpen}
                handleClose={closeDeleteDialog}
                title={'Are you sure you want to delete this station ?'}
                style={{
                    paddingTop: '20px',
                    paddingBottom: '20px'
                }}
                save={() => handleStationDelete(stationId)}
                saveTitle="Delete"
            />

            <DialogSlide
                open={isBumpDialogOpen}
                handleClose={closeBumpDialog}
                title={"Are you sure you want to bump this station's orders?"}
                style={{
                    paddingTop: '20px',
                    paddingBottom: '20px'
                }}
                save={() => BumpParkingStation(stationId)}
                saveTitle={'Bump'}
            />
        </div>
    );
}
