import Repository from './Repository';
const GUEST_WALL_SWITCH = '/guest-book';

export default {
  guestWallSwitchStatus() {
    return Repository.get(`${GUEST_WALL_SWITCH}`);
  },
  guestWallSwitch(payload) {
    return Repository.put(`${GUEST_WALL_SWITCH}/enable-disable`, payload);
  },
  tableChangeStatusAction() {
    return Repository.get(`table-sessions/table-change`);
  },
  enableDisableTableChangeStatus(payload) {
    return Repository.put(`table-sessions/enable-disable-table-change`, payload);
  }
};
