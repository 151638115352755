import { GET_BUSINESSES_LOADER } from 'store/types';
import {
  GET_BUSINESSES,
  SET_BUSINESSE,
  SET_BUSINESSE_MENUS,
  SET_BUSINESSE_MENU
} from 'store/types';

let initState = {
  getLoader: false,
  businesses: [],
  blId: '',
  bussines_menus: [],
  bussines_menu: ''
};
export default function (state = initState, action) {
  switch (action.type) {
    case GET_BUSINESSES:
      return {
        ...state,
        businesses: action.payload
      };
    case GET_BUSINESSES_LOADER:
      return {
        ...state,
        getLoader: action.payload
      };
    case SET_BUSINESSE:
      return {
        ...state,
        blId: action.payload
      };
    case SET_BUSINESSE_MENUS:
      return {
        ...state,
        bussines_menus: action.payload
      };
    case SET_BUSINESSE_MENU:
      return {
        ...state,
        bussines_menu: action.payload
      };
    default:
      return { ...state };
  }
}
