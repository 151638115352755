import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from 'store/actions/authAction';
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import { useDispatch } from 'react-redux';
import { setBusinesse } from 'store/actions/businessActions';
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient()

const App = () => {
  let { uid } = useSelector((state) => state.authUser);
  let { blId } = useSelector((state) => state.business);
  let dispatch = useDispatch();

  useEffect(() => {
    if (blId === '') {
      let selectedBussines = sessionStorage.getItem('blId');
      if (selectedBussines) {
        dispatch(setBusinesse(selectedBussines));
      } else {
        localStorage.clear();
        dispatch(logout());
      }
    }
  }, [blId]);
  return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {uid ? (
              <Switch>
                <Route path="/store" render={(props) => <AdminLayout {...props} lc_layout="store" />} />
                <Route path="/admin" render={(props) => <AdminLayout {...props} lc_layout="admin" />} />
                <Redirect from="*" to="/admin/stores/summary" />
              </Switch>
          ) : (
              <Switch>
                <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                <Redirect from="*" to="/auth/login" />
              </Switch>
          )}
        </BrowserRouter>
        <Toaster />
      </QueryClientProvider>

  );
};

export default App;
