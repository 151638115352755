import AuthRepository from './AuthRepository';
import ArticlesRepository from './ArticlesRepository';
import BusinessesRepository from './BusinessesRepository';
import IngredientsRepository from './IngredientsRepository';
import MenuRepository from './MenuRepository';
import CategoryRepository from './CategoryRepository';
import AllergenRepository from './AllergenRepository';
import ItemIngredientsRepository from './ItemIngredientsRepository';
import FloorPlansRepository from './FloorPlansRepository';
import ArticleMenusRepository from './ArticleMenusRepository';
import CourseRepository from './CourseRepository';
import Article_DetailRepository from './Article_DetailRepository';
import StationsRepository from './StationsRepository';
import UsersRepository from './UsersRepository';
import PromotionsRepository from './PromotionsRepository';
import GuestRepository from './GuestRepository';
import StoreSettingRepository from './StoreSettingRepository';
import BestSellerRepository from './BestSellerRepository';
import SpecialSellerRepository from './SpecialSellerRepository';
import SettingRepository from './SettingRepository';
import SyncDataRepository from './SyncDataRepository';
import PermisionRepository from './PermisionRepository';
import SessionRepository from './SessionRepository';

const repositories = {
  auth: AuthRepository,
  articles: ArticlesRepository,
  businesses: BusinessesRepository,
  ingredients: IngredientsRepository,
  menu: MenuRepository,
  category: CategoryRepository,
  allergen: AllergenRepository,
  item_ingredients: ItemIngredientsRepository,
  floor_plans: FloorPlansRepository,
  article_menus: ArticleMenusRepository,
  course: CourseRepository,
  article_details: Article_DetailRepository,
  stations: StationsRepository,
  user_detail: UsersRepository,
  promotion: PromotionsRepository,
  guest: GuestRepository,
  storesetting: StoreSettingRepository,
  bestseller: BestSellerRepository,
  specialseller: SpecialSellerRepository,
  sync_data: SyncDataRepository,
  permissions: PermisionRepository,
  appSettings: SettingRepository,
  session: SessionRepository
};

export const RepositoryFactory = {
  get: (name) => repositories[name]
};
