export const fontSize = [
  { value: '12px', label: '12px' },
  { value: '14px', label: '14px' },
  { value: '16px', label: '16px' },
  {
    value: '18px',
    label: '18px'
  },
  {
    value: '20px',
    label: '20px'
  },
  {
    value: '22px',
    label: '22px'
  },
  {
    value: '24px',
    label: '24px'
  },
  {
    value: '26px',
    label: '26px'
  },
  {
    value: '28px',
    label: '28px'
  },
  {
    value: '30px',
    label: '30px'
  },
  {
    value: '32px',
    label: '32px'
  },
  {
    value: '34px',
    label: '34px'

  },
  {
    value: '36px',
    label: '36px'
  },
  {
    value: '38px',
    label: '38px'
  },
  {
    value: '40px',
    label: '40px'

  },
  {
    value: '42px',
    label: '42px'
  },
  {
    value: '44px',
    label: '44px'
  },
  {
    value: '46px',
    label: '46px'

  }
];

export const fontfamily = [
  { value: 'poppins', label: 'Font Poppins' },
  { value: 'montserrat', label: 'Font Montserrat' },
  { value: 'nunito', label: 'Font Nunito' },
  { value: 'barlow', label: 'Font Barlow' },
  {value:'Source Sans Pro-Black' ,label: "Source Sans Pro-Black"},
  {value:'Source Sans Pro-BlackItalic' ,label: "Source Sans Pro-BlackItalic"},
  {value:'Source Sans Pro-Bold' ,label: "Source Sans Pro-Bold"},
  {value:'Source Sans Pro-BoldItalic' ,label: "Source Sans Pro-BoldItalic"},
  {value:'Source Sans Pro-ExtraLight' ,label: "Source Sans Pro-ExtraLight"},
  {value:'Source Sans Pro-Italic' ,label: "Source Sans Pro-Italic"},
  {value:'Source Sans Pro-LightItalic' ,label: "Source Sans Pro-LightItalic"},
  {value:'Source Sans Pro-Regular' ,label: "Source Sans Pro-Regular"},
  {value:'Source Sans Pro-SemiBold' ,label: "Source Sans Pro-SemiBold"},
  {value:'Source Sans Pro-SemiBoldItalic' ,label: "Source Sans Pro-SemiBoldItalic"},
];
