import Repository from './Repository';
const BEST_SELLER = '/best-seller';
export default {
  getBestSellers(payload) {
    // console.log("repo best seeleer ddata" )
    return Repository.get(`${BEST_SELLER}/${payload}`);
  },
  addBestSeller(payload) {
    return Repository.post(`${BEST_SELLER}`, payload);
  },
  removeBestSeller(payload) {
    return Repository.delete(`${BEST_SELLER}/${payload}`);
  }
};
