const initialData = {
  bestSeller: [],
  newlist: []
};

const bestSellerReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'GET_BEST_SELLER':
      return {
        ...state,
        bestSeller: action.payload
      };
    case 'ADD_BEST_SELLER':
      return {
        ...state,
        newlist: [...state.list, action.payload]
      };
    case 'REMOVE_BEST_SELLER':
      return {
        ...state,
        newlist: state.list.filter((elem) => elem.id !== action.id)
      };

    default:
      return state;
  }
};
export default bestSellerReducer;
