import { RepositoryFactory } from '../../repository/RepositoryFactory';
var allergenRepository = RepositoryFactory.get('allergen');

export const getAllergenLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'GET_ALLERGEN_LOADER',
    payload: val
  });
};

export const allergenLoader = (val) => async (dispatch) => {
  dispatch({
    type: 'ALLERGEN_LOADER',
    payload: val
  });
};

export const getAllAllergens = () => async (dispatch) => {
  dispatch(getAllergenLoader(true));
  const { data } = await allergenRepository.getAllAllergens();
  try {
    if (data.success) {
      dispatch({
        type: 'GET_ALLERGEN',
        payload: data.data
      });
      dispatch(getAllergenLoader(false));
    } else {
      dispatch(getAllergenLoader(false));
    }
  } catch (e) {
    dispatch({
      type: 'GET_ALLERGEN',
      payload: []
    });
    dispatch(getAllergenLoader(false));
  }
};

export const createAllergen =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(allergenLoader(true));
    try {
      let res = await allergenRepository.createAllergen(payload);
      if (res?.data?.success) {
        // alert('Allergen Added');
        dispatch(getAllAllergens());
        dispatch(allergenLoader(false));
        onSuccess();
        return res?.data?.success;
      } else {
        dispatch(allergenLoader(false));
      }
    } catch (e) {
      dispatch(allergenLoader(false));
    }
  };
export const updateAllergen =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(allergenLoader(true));
    try {
      let res = await allergenRepository.updateAllergen(payload);
      if (res?.data?.success) {
        // alert('Allergen Updated');
        dispatch(getAllAllergens());
        dispatch(allergenLoader(false));
        onSuccess();
        return res?.data?.success;
      } else {
        dispatch(allergenLoader(false));
      }
    } catch (e) {
      dispatch(allergenLoader(false));
    }
  };
export const deleteAllergen =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(allergenLoader(true));
    try {
      let res = await allergenRepository.deleteAllergen(payload);
      if (res?.data?.success) {
        // alert('Allergen Deleted');
        dispatch(allergenLoader(false));
        dispatch(getAllAllergens());
        onSuccess();
        return res?.data?.success;
      } else {
        dispatch(allergenLoader(false));
      }
    } catch (e) {
      dispatch(allergenLoader(false));
    }
  };
