import Repository from './Repository';
const ALLERGENS = '/allergen';
export default {
  getAllAllergens() {
    return Repository.get(`${ALLERGENS}`);
  },
  createAllergen(payload) {
    let form = new FormData();
    form.append('title', payload.title);
    form.append('file', payload.image);
    return Repository.post(`${ALLERGENS}`, form);
  },
  updateAllergen(payload) {
    let form = new FormData();
    form.append('title', payload.title);
    form.append('file', payload.image);
    return Repository.put(`${ALLERGENS}/${payload.id}`, form);
  },
  deleteAllergen(payload) {
    return Repository.delete(`${ALLERGENS}/${payload}`, {});
  }
};
