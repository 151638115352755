import Repository from './Repository';
const GET_BUSINESSES = '/business';
const GET_BUSINESSES_Menus = '/menus/bussiness-menus';

export default {
  getBusinesses() {
    return Repository.get(`${GET_BUSINESSES}`);
  },
  getBusinessesMenus(payload) {
    return Repository.get(`${GET_BUSINESSES_Menus}/${payload}`);
  }
};
