let initState = {
  getArticleDetailsLoader: false,
  articleDetailsLoader: false,
  article_details: [],
  article_description: {}
};
export default function (state = initState, action) {
  switch (action.type) {
    case 'GET_ARTICLE_DETAILS':
      return {
        ...state,
        article_details: action.payload
      };
    case 'GET_ARTICLE_DESCRIPTION':
      return {
        ...state,
        article_description: action.payload
      };
    case 'ARTICLE_DETAIL_LOADER':
      return {
        ...state,
        articleDetailsLoader: action.payload
      };
    case 'GET_ARTICLE_DETAIL_LOADER':
      return {
        ...state,
        getArticleDetailsLoader: action.payload
      };
    default:
      return { ...state };
  }
}
