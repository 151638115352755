import { LOGIN, LOGOUT } from '../types';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
var authRepository = RepositoryFactory.get('auth');

export const login = (payload) => async (dispatch) => {
  try {
    let { data } = await authRepository.login(payload);
    console.log('data', data);
    dispatch({
      type: LOGIN,
      payload: data
    });
  } catch (err) {
    console.log('error', err);
    alert(err.response.data.message);
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT
  });
};
