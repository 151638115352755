import React from 'react';
const PageTitle = ({ title, icon, secondary, value }) => {
  return (
    <div className=" d-flex align-items-center">
      {icon && <img src={icon} className="mr-3" alt="icon" height="30px" />}
      <h4 className="h4 m-0 font-weight-bold text-dark">{title}</h4>
      {secondary == true && <h4 className="ml-auto h4 m-0 font-weight-bold text-dark">{value}</h4>}
    </div>
  );
};

export default PageTitle;
