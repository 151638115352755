import Repository from './Repository';
const STORESETTING = '/business-logo';
export default {
  getStoreLogo(payload) {
    return Repository.get(`${STORESETTING}/${payload}`);
  },
  addStoreLogo(payload) {
    let form = new FormData();
    form.append('blId', payload.blId);
    form.append('file', payload.file);
    return Repository.post(`${STORESETTING}`, form);
  }
};
