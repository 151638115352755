import Repository from './Repository';
const FLOOR_PLANS = '/floor-plans';
const SESSIONS = '/table-sessions';
const ORDER = '/order/';
const businessId = localStorage.getItem('blId') || 660076343853236;
export default {
  getAllFloorplans(payload) {
    return Repository.get(`${FLOOR_PLANS}/${payload}`);
  },
  getAllSessions(payload) {
    return Repository.get(`${SESSIONS}/${payload}`);
  },
  removeSession(payload) {
    return Repository.delete(`${SESSIONS}/${payload}`);
  },
  getOrders(payload) {
    return Repository.get(`${ORDER + `test-orders/${payload?payload:businessId}`}`);
  },
  payWaiterOrder(payload) {
    return Repository.post(`/order/waiter-print`, payload).then((res) => {
      return res;
    });
  }
};
