import React from 'react';
import food from 'assets/img/download.jpg';
import { Row, Col, Button } from 'reactstrap';
import PromotionCard from 'components/PromotionCard';

const PromotionSectionTakeaway = (props) => {
  return (
    <div className="mb-5">
      <div className="d-flex align-items-center">
        <h5 className="mb-0 text-black">{props.title}</h5>
        <Button color="primary" className="ml-auto">
          Save
        </Button>
      </div>
      {/* <Row>
	<Col xs="12" sm="12" md="6" lg="6">
		<div className="card"></div>
	</Col>
</Row> */}
      <div className="mt-3 ">
        <Row className="mt-2">
          <Col lg="6" md="6" sm="12" xs="12" className="card">
            <Row>
              <Col lg="6" md="6" sm="12" xs="12" className="border">
                <small className="font-weight-bold">Color suggestions</small>
                <div className="p-4">
                  <div className=" mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-danger"></i>
                    <select className="rounded-pill ml-2 px-2 ">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center ">
                    <i className="fas fa-square text-primary"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-secondary"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-warning"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-info"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                  <div className="mt-1 d-flex align-items-center">
                    <i className="fas fa-square text-success"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>{' '}
                  <div className="mt-1 mb-1 d-flex align-items-center">
                    <i className="fas fa-square text-danger"></i>
                    <select className="rounded-pill ml-2 px-2">
                      <option value="Select item">Select item</option>
                      <option value="a">a</option>
                      <option value="a">a</option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col lg="6" md="6" sm="12" xs="12" className="border ">
                <small className="font-weight-bold">Best seller</small>
                <div className="w-100 ">
                  <div className="d-flex justify-content-center">
                    <select className=" rounded-pill w-75 mt-4 p-1">
                      <option value="select item">select item</option>
                      <option value="selecet">select</option>
                    </select>
                  </div>

                  <div className="d-flex flex-row justify-content-center mt-4">
                    <i className="fas fa-times-circle text-primary mx-2 mt--1"></i>
                    <h6>Truffle pinsa</h6>
                    <i className="fas fa-times-circle text-primary mx-2 mt--1"></i>
                    <h6>Truffle pinsa</h6>
                  </div>
                  <div className="d-flex flex-row justify-content-center mt-2">
                    <i className="fas fa-times-circle text-primary mx-2 mt--1"></i>
                    <h6>Truffle pinsa</h6>
                    <i className="fas fa-times-circle text-primary mx-2 mt--1"></i>
                    <h6>Truffle pinsa</h6>
                  </div>
                  <div className="d-flex flex-row justify-content-center mt-2">
                    <i className="fas fa-times-circle text-primary mx-2 mt--1"></i>
                    <h6>Truffle pinsa</h6>
                    <i className="fas fa-times-circle text-primary mx-2 mt--1"></i>
                    <h6>Truffle pinsa</h6>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="6" md="6" sm="12" xs="12" className="card">
            <Row>
              <Col lg="3" md="3" sm="12" xs="12">
                <div className="border mt-2 pl-2 pr-2 pb-4">
                  <small className="font-weight-bold">Pop up</small>
                  <div>
                    <PromotionCard image={food} />
                  </div>
                </div>
              </Col>
              <Col lg="3" md="3" sm="12" xs="12">
                <div className="border mt-2 pl-2 pr-2 pb-4">
                  <small className="font-weight-bold">Pop up</small>
                  <div>
                    <PromotionCard image={food} />
                  </div>
                </div>
              </Col>
              <Col lg="3" md="3" sm="12" xs="12">
                <div className="border mt-2 pl-2 pr-2 pb-4">
                  <small className="font-weight-bold">New Section</small>
                  <div>
                    <PromotionCard image={food} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PromotionSectionTakeaway;
