import CreateSessionModal from 'components/Basic Modal/CreateSessionModal';
import ErrorAlert from 'components/Basic Modal/ErrorAlert';
import FloorPlanCard from 'components/FloorPlanCard';
import PageTitle from 'components/PageTitle';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import { socket } from 'services/services.sockets';
import { getAllOrders, getAllSessions } from 'store/actions/floorPlanActions';
// import { socket } from "../../../socket-config/sockets";

const FloorPlans = () => {
  const dispatch = useDispatch();
  const [warn, setWarn] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [openAddSessionModal, setOpenAddSessionModal] = useState(false);

  const { removeSessionLoader, allOrders, getFloorPlansLoader, sessions } = useSelector(
    (state) => state.floor_plans
  );
  // const updateSessionCardLoader = useSelector ((state)=>state?.floor_plans?.updateSessionLoader)

  const { blId } = useSelector((state) => state.blId);
  useEffect(() => {
    dispatch(getAllSessions(blId));
    dispatch(getAllOrders(blId));
  }, [blId]);
  useEffect(() => {
    if (allOrders?.length && allOrders?.length) {
      let array = [];
      allOrders?.filter((_item) => _item.isParent)?.forEach((item) => {
        let isExsist = sessions?.find(
          (_itm) => _itm?._id === item?.sessionId && item?.salesEntries?.length
        );
        if (isExsist?._id) {
          array.push({
            ...item,
            ...isExsist
          });
        }
      });
      setOrders([...array]);
    }
  }, [sessions, allOrders]);
  useEffect(() => {
    // order created
    socket.on('order_received', () => {
      dispatch(getAllOrders());
    });
    // processing
    socket.on('updateProcess', () => {
      dispatch(getAllOrders());
    });
  }, []);

  useEffect(() => {
    socket.on('order_received', () => {
      console.log('Received a new order');
      dispatch(getAllOrders());
    });
  }, []);

  // console.log('ses--?',sessions)
  return (
    <>
      <Row className="p-3">
        <Col lg="6" sm="12" md="6" xs="12">
          <div className="card p-2 border border-light rounded ">
            <PageTitle icon={'/img/sidebar/map.png'} title="Sessions" />
          </div>
        </Col>
        <Col lg="12" sm="12" md="12" xs="12">
          {getFloorPlansLoader ? (
            <Spinner className="d-flex mx-auto mt-5" size="lg" color="primary" />
          ) : (
            <Row className="p-3">
              {orders &&
                orders?.map((data) => {
                  return data.payment_status !== 'paid' ? (
                    <FloorPlanCard
                      data={data}
                      blId={blId}
                      setSelectedOrder={setSelectedOrder}
                      setWarn={setWarn}
                      setAlertMsg={setAlertMsg}
                      loading={removeSessionLoader}
                      setOpenAddSessionModal={setOpenAddSessionModal}
                      openAddSessionModal={openAddSessionModal}
                    />
                  ) : null;
                })}
              {/* <FloorPlanCard title={"Andre"} />
                <FloorPlanCard title={"Daniele"} />
    
                <FloorPlanCard title={"Chanh Le"} />
                <FloorPlanCard title={"Andre"} />
                <FloorPlanCard title={"Daniele"} /> */}
            </Row>
          )}
        </Col>
      </Row>
      <ErrorAlert alert={warn} setAlert={setWarn} alertMessage={alertMsg} />
      <CreateSessionModal
        setWarn={setWarn}
        setAlertMsg={setAlertMsg}
        setOpenAddSessionModal={setOpenAddSessionModal}
        selectedOrder={selectedOrder}
        openAddSessionModal={openAddSessionModal}
      />
    </>
  );
};

export default FloorPlans;
