import { RepositoryFactory } from 'repository/RepositoryFactory';

let GuestRepository = RepositoryFactory.get('guest');

export const getAllGuests = (id) => async (dispatch) => {
  console.log(id, 'bild');
  try {
    let { data } = await GuestRepository.getAllGuestWalls(id);
    console.log('guest data', data);
    if (data.success) {
      console.log('data success', data);
      dispatch({
        type: 'GET_GUEST_WALLS',
        payload: data.data
      });
    }
  } catch (err) {
    console.log('error', err.message);
  }
};
export const updateGuestWall = (payload, blId) => async (dispatch) => {
  console.log(payload, 'id update');
  try {
    let res = await GuestRepository.updateGuestWall(payload);
    if (res?.data?.success) {
      // alert('Guest Wall Updated succesfully');
      dispatch(getAllGuests(blId));
    }
    return res?.data?.success;
  } catch (err) {
    console.log('error', err.message);
  }
};
export const deleteGuestWall = (payload, blId) => async (dispatch) => {
  console.log(payload, 'id delete');
  try {
    let res = await GuestRepository.deleteGuestWall(payload);
    if (res?.data?.success) {
      // alert('Guest Wall Deleted succesfully');
      dispatch(getAllGuests(blId));
    }
    return res?.data?.success;
  } catch (err) {
    console.log('error', err.message);
  }
};
