import React, { useEffect } from 'react';
import {  Col, Row, Spinner } from 'reactstrap';
// import logo from '../../assets/img/logo.png';
// import { Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
// import ReportCard from 'components/ReportCard';
import SummaryCard from 'components/SummayCard/summaryCard';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinesses } from 'store/actions/businessActions';
import { useHistory } from 'react-router-dom';
import { addBlId } from '../../store/actions/summaryActions';
// import io from "socket.io-client";

const Summary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { businesses, getLoader } = useSelector((state) => state.business);
  const bussinessId = sessionStorage.getItem('blId');
  // const socket = io.connect("https://vapiano-staging.herokuapp.com");

  useEffect(() => {
    // socket.on("problem_received", async (data) => {
    //   alert(
    //     `There is a problem on Table ${
    //       data?.tableNo
    //     } with Session ID ${data?.sessionID.substr(
    //       data?.sessionID.length - 4
    //     )} kindly check that out`
    //   );
    // });
  }, []);

  useEffect(() => {
    if (businesses.length == 0) {
      dispatch(getBusinesses());
    }
  }, []);

  return (
    <>
      <Row className="p-3">
        <Col xs="12" sm="12" md="12" lg="12">
          {/* <Row>
            <Col xs="12" sm="12" md="6" lg="6">
              <div className=" align-items-center card p-2 border border-light rounded  d-flex flex-row">
                <PageTitle title="Stores" icon="/img/sidebar/store.png" />
                <img src={logo} height="35px" className="ml-3" alt="logo" />
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <Button
                tag={Link}
                to="/admin/stores"
                color="primary"
                className="ml-auto mt-sm mt-2 btn-adminstore float-right"
              >
                Admin Stores
                <i className="ml-2 fa fa-plus-circle"></i>
              </Button>
            </Col>
          </Row> */}

          {/* <Row className="mt-4 justify-content-center">
            <Col xs="12" sm="12" md="12" lg="12">
              <ReportCard headerTitle={'Total Income'} />
            </Col>
          </Row> */}
          <Row className="mt-4 d-flex justify-content-center">
            <Col xs="12" sm="12" md="6" lg="6">
              <div className=" d-flex flex-row mt-2 ">
                <div className="card p-3 border border-light rounded w-100">
                  <PageTitle icon={'/img/sidebar/store.png'} title="Stores" />
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6"></Col>
          </Row>

          {/* Second Partition */}
          {getLoader ? (
            <div className="text-center">
              <Spinner size="lg" />
            </div>
          ) : (
            <Row className="mt-4 d-flex justify-content-center">
              {businesses &&
                businesses.map((business, idx) => {
                  return String(business.blId) === bussinessId ? (
                    <Col
                      key={idx}
                      xs="12"
                      sm="12"
                      md="6"
                      lg="6"
                      className="cursor-pointer"
                      onClick={() => {
                        localStorage.setItem(
                          'blId',
                          // business
                          // 	.businessLocations[0]
                          // 	.blId
                          // business
                          // 	.businessLocations[1]
                          // 	.blId
                          business.blId
                        );
                        localStorage.setItem('blName', business.blName);
                        dispatch(
                          addBlId(
                            business.blId
                            // business
                            // 	?.businessLocations[0]
                            // 	?.blId
                          )
                        );
                        history.push('/store/settings');
                      }}
                    >
                      {/* <Link to="/admin/stores" className="text-decoration-none"> */}

                      <SummaryCard cardName={business.blName} />
                    </Col>
                  ) : (
                    <></>
                  );
                })}
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Summary;
