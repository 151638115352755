import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import DayAndTime from './DayAndTime';

const SettingsModal = (props) => {
  return (
    <>
      <Modal centered isOpen={props.show} toggle={props.handleClose}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
          <DayAndTime />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SettingsModal;
