/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import DialogSlide from './Dialog'
import {
    Button,
    Form,
    Input
} from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { absoluteUrl } from 'utils/backendUrl';
import { successToast } from 'components/Toasts/toast';
import { errorToast } from 'components/Toasts/toast';
import { useMutation, useQueryClient  } from '@tanstack/react-query';

const ParkingStationsConfiguration = ({
                                          isConfigModalOpen,
                                          handleCloseConfigModal,
                                      }) => {
    const { blId } = useSelector((state) => state.business);
    const queryClient = useQueryClient()

    const [parkingStaitonsNumber, setParkingStationsNumber] = useState()

    const handleParkingStationNumber = useCallback((e) => {

        const updateTimeOut = setTimeout(() => setParkingStationsNumber(e.target.value), 500)

        return () => {
            clearTimeout(updateTimeOut)
        }

    }, [parkingStaitonsNumber])



    const createParkingStations = useMutation({

        mutationFn: async() => {
            const parkingStations = await axios.post(absoluteUrl(`/parkingstations/createNewStations/${parkingStaitonsNumber}`))

            if(!parkingStations) {
                errorToast("❌ Error happened while creating new parking stations")
                throw new Error('Error occured while creating new parking stations !')
            }

            successToast("🎉 New parking stations have been created successfully!")

            return parkingStations
        },
        onSuccess: () => {
            // this refetches again the groups from server
            // just as a backup plan
            queryClient.invalidateQueries(['allParkingStations'])

        }
    })

    const handleParkingStationCreation = async () => {
        try {
            createParkingStations.mutate()
        } catch (error) {
            console.log("ERROR_UPDATING_GROUPS_ORDER", error)
        }
    }


    return (
        <DialogSlide
            open={isConfigModalOpen}
            handleClose={handleCloseConfigModal}
            title={'Wie viele Parkstationen möchten Sie hinzufügen?'}
            style={{
                paddingTop: '20px',
                paddingBottom: '20px',
            }}
        >
            <div>
                <Form
                    onSubmit={(e)=> {
                        e.preventDefault()
                        handleParkingStationCreation()
                        handleCloseConfigModal()
                    }}

                    className='flex flex-col mt-4'
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        direction: "column",
                        gap: "10px"
                    }}
                >

                    <Input
                        type="text"
                        className="form-control w-75"
                        placeholder={"Number of parking stations"}
                        required
                        defaultValue={
                            parkingStaitonsNumber
                        }
                        onChange={handleParkingStationNumber}
                    />

                    <Button disabled={false} color="primary" type="submit">
                        Add
                    </Button>
                </Form>

            </div>
        </DialogSlide>

    )
}

export default ParkingStationsConfiguration