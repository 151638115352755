
import { useSelector } from 'react-redux';
import TextUpdate from '.';

const UpdateText = () => {
  const updatedText = useSelector((state) => state?.settings?.updatedText);

  // const popups = updatedText?.filter(updated => ['uups', 'order'].includes(updated?.modal_type));
  const orderPopData = updatedText?.filter(updated =>  "order" .includes(updated?.modal_type))[0];
  const uupsPopData = updatedText?.filter(updated =>  "uups" .includes(updated?.modal_type))[0];


   const modalsComp = ["uups","order"]?.map(el => (
      <TextUpdate
      key={el.id}
      titlePage={el === 'uups' ? 'UUPS POPUP TEXT' : 'ORDER POPUP TEXT'}
      modal_type={el}
      heading_text={"HEADING TITLE"}
      primary_text={"PRIMARY TITLE"}
      secondary_text={"SECONDARY TITLE"}
      data={el === 'uups'? uupsPopData : orderPopData}
    />
   ));

 

  return (
    <>
      {modalsComp}

    </>
  );
};

export default UpdateText;
