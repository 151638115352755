import React from 'react';

const AddText = ({ menuTitle,name,value }) => {

  return (
        <div className="mt-2">
          <div className="p-2 mt-2 d-flex align-items-center justify-content-between">
            <label className='w-25'>{menuTitle}</label>
            <input
              type="text"
              ref={name}
              placeholder={value}
              className="w-75 py-3 px-2"
              alt="image_upload"
              style={{
                border: '1px solid #bdc3c7'
              }}
            />
          </div>
        </div>

  );
};

export default AddText;
