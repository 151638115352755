import {
  GET_BUSINESSES,
  SET_BUSINESSE,
  SET_BUSINESSE_MENUS,
  SET_BUSINESSE_MENU
} from 'store/types';
import { GET_BUSINESSES_LOADER } from 'store/types';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
var businessesRepository = RepositoryFactory.get('businesses');
export const getLoader = (val) => async (dispatch) => {
  dispatch({
    type: GET_BUSINESSES_LOADER,
    payload: val
  });
};
export const getBusinesses = () => async (dispatch) => {
  dispatch(getLoader(true));
  try {
    let { data } = await businessesRepository.getBusinesses();
    if (data.success) {
      dispatch({
        type: GET_BUSINESSES,
        payload: data?.data[0]?.businessLocations
      });
      dispatch(getLoader(false));
    } else {
      dispatch(getLoader(false));
    }
    return data?.data[0]?.businessLocations;
  } catch (e) {
    dispatch(getLoader(false));
  }
};
export const setBusinesse = (data) => async (dispatch) => {
  try {
    sessionStorage.setItem('blId', data);
    dispatch({
      type: SET_BUSINESSE,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
};
export const getBusinesseMenus = (id) => async (dispatch) => {
  try {
    let { data } = await businessesRepository.getBusinessesMenus(id);
    dispatch({
      type: SET_BUSINESSE_MENUS,
      payload: data.data
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const setBusinesseMenu = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_BUSINESSE_MENU,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
};
