import { LOGIN, LOGOUT } from '../types';

const initialState = {
  user: null,
  uid: null
};

/*
    Any action related to Profile will go here.
*/

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        user: payload.user,
        uid: payload.token
      };
    case LOGOUT:
      console.log('logout reducer');
      return {
        ...state,
        user: null,
        uid: null
      };
    default:
      return { ...state };
  }
}
