let initState = {
  getItemIngredientsLoader: false,
  itemIngredientsLoader: false,
  item_ingredients: []
};
export default function (state = initState, action) {
  switch (action.type) {
    case 'GET_ITEM_INGREDIENTS':
      return {
        ...state,
        item_ingredients: action.payload
      };
    case 'ITEM_INGREDIENTS_LOADER':
      return {
        ...state,
        itemIngredientsLoader: action.payload
      };
    case 'GET_ITEM_INGREDIENTS_LOADER':
      return {
        ...state,
        getItemIngredientsLoader: action.payload
      };
    default:
      return { ...state };
  }
}
