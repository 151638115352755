import { CREATE_SESSION_FAIL } from 'store/Types/SessionType';
import { CREATE_SESSION_SUCCESS } from 'store/Types/SessionType';
import { CREATE_SESSION_REQUEST } from 'store/Types/SessionType';

const initState = {
  loading: false,
  session: [],
  error:[],
};
export default function (state = initState, action) {
  switch (action.type) {
    case CREATE_SESSION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        session: action.payload,
        loading: false
      };
      case CREATE_SESSION_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        };
      
    default:
      return { ...state };
  }
}
