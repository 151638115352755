import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React from 'react';
const ErrorModal = (props) => {
  return (
    <>
      <Modal
        isOpen={props.display}
        className={`${props.classname && props.classname} p-md-2 p-0`}
        centered={props?.center}
        toggle={props.handleClose}
      >
        <ModalHeader className={props.className}>
          {' '}
          <h1>{props.title}</h1>
        </ModalHeader>
        <ModalBody className={props.bodyClass} style={props.bodyStyles}>
          <>{props.content}</>
        </ModalBody>
        {props.footer && (
          <ModalFooter className="justify-content-center">
            <Button
              variant="danger"
              onClick={() => {
                props.handleClose();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};
export default ErrorModal;
