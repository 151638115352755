import Repository from './Repository';
const ARTICLE_DETAIL = '/article-detail';
const ARTICLE_DESCRIPTION = '/articles/article-description';
export default {
  getArticleDetails(payload, bid) {
    return Repository.get(`${ARTICLE_DETAIL}/${payload}?blId=${bid}`);
  },
  addArticleDetails(payload) {
    let form = new FormData();

    form.append('blId', payload.blId);
    form.append('article_id', payload.article_id);
    form.append('categories', JSON.stringify(payload.categories));
    form.append('allergens', JSON.stringify(payload.allergens));
    form.append('menus', JSON.stringify(payload.menus));
    form.append('file', payload.image);
    form.append('course', payload.course);
    form.append('related_products', JSON.stringify(payload.related_products));
    form.append('station', payload.station);
    form.append('ingredients', JSON.stringify(payload.ingredients));
    form.append('description', payload.description);
    form.append('single_only', JSON.stringify(payload.single_only));
    form.append('prep_time', payload.prep_time);
    form.append('mandatory', JSON.stringify(payload.mandatory));
    return Repository.post(`${ARTICLE_DETAIL}`, form);
  },
  getArticleDescription(payload, blId) {
    return Repository.get(`${ARTICLE_DESCRIPTION}/${payload}?blId=${blId}`);
  },
  addMenuInArticleDetails(payload) {
    return Repository.put(`${ARTICLE_DETAIL}/menu-detail`, payload);
  }
};
