import React, {useCallback, useEffect, useState} from 'react'
import DialogSlide from './Dialog'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Button, Spinner} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
// import {positionDragItem} from 'store/actions/menuActions';
import {Switch} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import { getAllMenus } from 'store/actions/menuActions';
import { absoluteUrl } from 'utils/backendUrl';



export default function ReorderDialog({isConfigModalOpen, handleCloseConfigModal,}) {
    const {
        getMenuLoader,
        menus,
        loading: menuDragLoading
    } = useSelector((state) => state.menu);

    // let [activeMenus, setActiveMenus] = useState([]);
    // const [groups, setGroups] = useState([]);
    const group  =useSelector((state)=>state.menu.group)
    const loadingGroup  =useSelector((state)=>state.menu.loadingGroup)
    const dispatch = useDispatch();
    const [loadupdate,setLoadupdate]=useState(false)
    const {blId} = useSelector((state) => state.business);
    const [isDragConfirmationOpen, setIsDragConfirmationOpen] = useState(false); // Track drag confirmation
    const [draggedSection, setDraggedSection] = useState(null);


    const handleConfirmDrag = async () => {
        setLoadupdate(false);
    
        if (draggedSection) {
          try {
            const response = await axios.put(absoluteUrl(`/group-logo/order/${blId}`), {
              ...draggedSection,
            });
    
            if (response.data.success) {
              console.log(draggedSection);
              console.log('__after__update', response.data);
              
              // Manually trigger a refetch to update 'allGroups'
            //   await refetch();
              
              dispatch(getAllMenus(blId))
              setLoadupdate(true);
              setIsDragConfirmationOpen(false);
            }
          } catch (error) {
            console.error('Error updating group order:', error);
          }
        }
      };

    const handleCancelDrag = () => {
        setIsDragConfirmationOpen(false);
    };

    const handleDragEnd =  (result) => {
        if (!result.destination) return; 
        // const items = Array.from(allGroups);
        const items = Array.from(group);
        const updatedSection = {
            group1: items[result.source.index].group,
            group2: items[result.destination.index].group,
        };
        console.log("__updatedSection",updatedSection)
        const updatedSection2 = {
            startIndex: result.source.index,
            endIndex: result.destination.index,
            // menu_tags: tag,
        };
       console.log("__updatedSection2",updatedSection2)
        setDraggedSection(updatedSection2);
        dispatch(getAllMenus(blId))
        // refetch();
        setIsDragConfirmationOpen(true);
      };
    useEffect(() => {
    //    console.log("__allGroups",allGroups);
       console.log("__group",group);
       console.log("__loadupdate",loadupdate);
      console.log("__loadingGroup",loadingGroup);
    }, [menus,group,loadupdate]);

    const [isDraggable, setIsDraggable] = useState(false)
    const toggleDraggable = useCallback(
        (event) => {
            event.target.checked ? setIsDraggable(true) : setIsDraggable(false);
        },
        []
    )




    return (
        //  article configuration dialog
        <DialogSlide
            open={isConfigModalOpen}
            handleClose={handleCloseConfigModal}
            title={'Artikel neu positionieren'}
            // save={handleReorder}
        >
            <div className='flex flex-col gap-2 w-full'>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isDraggable}
                                onChange={toggleDraggable}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        }
                        label="Neuausrichtung"
                        labelPlacement="start"
                    />
                </div>
                {!isDragConfirmationOpen && (<DragDropContext
                        onDragEnd={handleDragEnd}
                    >
                        <Droppable droppableId="droppableArticle">
                            {(provided) => {
                                return (
                                    <div ref={provided.innerRef}>
                                        {<>{getMenuLoader || menuDragLoading   || !loadingGroup?(
                                            <Spinner className="d-flex mx-auto mt-5" size="lg" color="primary"/>
                                        ) : (
                                            <>

                                                {menus && group  ? (
                                                    <>
                                                        {group?.map((data, k) => {
                                                            return (<>
                                                                    <Draggable
                                                                         key={data.id}
                                                                         draggableId={data.id}
                                                                         index={k}
                                                                         isDragDisabled={!isDraggable}
 
                                                                    >
                                                                        {(provided) => {
                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        className="d-flex align-items-center p-3"
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        {isDraggable &&
                                                                                            <DragHandleIcon/>}

                                                                                        <span
                                                                                            style={{
                                                                                                justifyContent: 'space-between',
                                                                                                display: 'flex',
                                                                                                width: '100%'
                                                                                            }}
                                                                                        >
                                                  <h6 className="ml-3 mt-1"
                                                      style={{
                                                          color: isDraggable ? 'black' : 'gray',
                                                      }}
                                                  >
                                                    {data.group}
                                                  </h6>
                                                  <span
                                                      style={{
                                                          fontStyle: 'italic'
                                                      }}
                                                  >
                                                    {data.position}
                                                  </span>
                                                </span>
                                                                                    </div>
                                                                                </>)
                                                                        }}</Draggable>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <div className="p-5">Empty menu</div>
                                                )}
                                            </>
                                        )}
                                        </>
                                        }
                                    </div>
                                )
                            }
                            }
                        </Droppable>
                    </DragDropContext>
                )}

                {/* Confirmation Dialog for Drag and Drop */}
                <DialogSlide
                    title={'Sind Sie sicher, dass Sie die Änderungen speichern möchten ?'}
                    open={isDragConfirmationOpen}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <div style={{display: 'flex', gap: '1rem'}}>
                            <Button
                                color={'danger'}
                                onClick={handleCancelDrag}
                            >
                                Stornieren
                            </Button>
                            <Button
                                color={'primary'}
                                onClick={handleConfirmDrag}
                            >
                                Bestätigen
                            </Button>
                        </div>
                    </div>
                </DialogSlide>

            </div>
        </DialogSlide>
    )
}

