const initialData = {
  specialSeller: [],
  newlist: []
};

const specialSellerReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'GET_SPECIAL_SELLER':
      return {
        ...state,
        specialSeller: action.payload
      };
    case 'ADD_SPECIAL_SELLER':
      return {
        ...state,
        newlist: [...state.list, action.payload]
      };
    case 'REMOVE_SPECIAL_SELLER':
      return {
        ...state,
        newlist: state.list.filter((elem) => elem.id !== action.id)
      };

    default:
      return state;
  }
};
export default specialSellerReducer;
